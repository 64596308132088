import { useState, React, useEffect } from 'react';
import {
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  StepButton,
  Switch
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Carriers.css';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useHistory } from 'react-router-dom';
import { CardContent, Radio, FormControlLabel } from '@mui/material';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
  CreateOrUpdateCarrier,
  GetAgencyLocations,
  GetCarrierLocations,
  UploadCarrierLogo,
  UploadUserProfileImage
} from '../../apis/Agency';
import { ErrorToast, successToast } from '../../components/Toasts';
import UploadIcon from '@mui/icons-material/Upload';
import { ToastContainer } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { base_logo_url, base_url } from '../../apis/constants';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';

export default function CreateCarrier(props) {
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const AgencyEmail = useSelector(state => state.userDetails.email);
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );

  const EditedData = props?.location?.state;
  const history = useHistory();

  const [shipmentFailed, setShipmentFailed] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTabLabel, setActiveTabLabel] = useState('Detail');
  // agency states
  const options = ['Active', 'In-Active'];
  const [agencyActiveValue, setAgencyActiveValue] = useState(
    EditedData?.active === false ? options[1] : options[0]
  );

  const carrierTypes = [
    'Bulk Tanker',
    'Dry Van',
    'Reefer',
    'Box Truck',
    'Sprinter',
    'Intermodal'
  ];
  const [carrierType, setCarrierType] = useState(
    EditedData?.cSubTypes ? EditedData?.cSubTypes : []
  );
  const [agencyStatus, setAgencyStatus] = useState(true);
  const [carrierName, setCarrierName] = useState(
    EditedData?.name ? EditedData?.name : ''
  );
  const [address1, setAddress1] = useState(
    EditedData?.streetAddress1 ? EditedData?.streetAddress1 : ''
  );
  const [address2, setAddress2] = useState(
    EditedData?.streetAddress2 ? EditedData?.streetAddress2 : ''
  );
  const [zip, setZip] = useState(
    EditedData?.postalCode ? EditedData?.postalCode : ''
  );
  const [city, setCity] = useState(EditedData?.city ? EditedData?.city : '');
  const [state, setState] = useState(
    EditedData?.state ? EditedData?.state : ''
  );
  const [country, setCountry] = useState(
    EditedData?.country ? EditedData?.country : ''
  );
  const [createAgencyLoader, setCreateAgencyLoader] = useState(false);
  const [agencyImage, setAgencyImage] = useState(
    EditedData?.logo ? base_logo_url + EditedData?.logo : null
  );
  const [agencyApiUploadedImage, setAgencyApiUploadedImage] = useState(
    EditedData?.logo ? EditedData?.logo : ''
  );

  // validations
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  // Carriers state
  const [carriersLoader, setCarriersLoader] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [allCarriersChecked, setAllCarriersChecked] = useState(false);

  const [selectedCarriersIdArray, setSelectedCarriersIdArray] = useState([]);
  // admin States
  const [firstName, setFirstName] = useState(
    EditedData?.contactFirstName ? EditedData?.contactFirstName : ''
  );
  const [lastName, setLastName] = useState(
    EditedData?.contactLastName ? EditedData?.contactLastName : ''
  );
  const [email, setEmail] = useState(
    EditedData?.contactEmail ? EditedData?.contactEmail : ''
  );
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(
    EditedData?.contactPhone ? EditedData?.contactPhone : ''
  );
  const [designation, setDesignation] = useState(
    EditedData?.contactDesignation ? EditedData?.contactDesignation : ''
  );
  const [isValid, setIsValid] = useState(true);
  const [adminActiveValue, setAdminActiveValue] = useState(options[0]);
  const [adminStatus, setAdminStatus] = useState(true);
  const [adminImage, setAdminImage] = useState(null);
  const [carrierAdminImageURL, setCarrierAdminImageURL] = useState(null);
  const [userLocations, setUserLocations] = useState([]);
  const [agencyLocations, setAgencyLocations] = useState([]);
  const [adminRoles, setAdminRoles] = useState(0);
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [agencyLocationsLoader, setAgencyLocationsLoader] = useState(false);

  const handleAssignedLocations = agencyLocations => {
    let tempArr = [];
    GetCarrierLocations(AgencyID, EditedData?.carrierId)
      .then(res => {
        res.status === 200 && console.log('LocationsSuccess--->');
        for (let i = 0; i < res.data.data.locationIds.length; i++) {
          const locationId = res.data.data.locationIds[i];
          const filterdAssignedLocation = agencyLocations.find(
            locations => locationId === locations.id
          );
          tempArr.push(filterdAssignedLocation);
        }
        setUserLocations(tempArr);
      })
      .catch(err => console.log('LocationsErr---->>>'));
  };
  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Carriers'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, []);

  useEffect(() => {
    setAgencyLocationsLoader(true);
    GetAgencyLocations(AgencyID, 0)
      .then(response => {
        if (response.status === 200) {
          setAgencyLocationsLoader(false);
          setAgencyLocations(response.data.data);
          if (EditedData?.carrierId) {
            handleAssignedLocations(response.data.data);
          }
        }
      })
      .catch(err => console.log('eeeeeeeee', err.message));
  }, []);

  const handleAllActiveCarriers = event => {
    setAllCarriersChecked(event.target.checked);
    for (let i = 0; i < carriers.length; i++) {
      // const element = array[i];
      const tempIDs = [...selectedCarriersIdArray];
      tempIDs[i].assigned = event.target.checked;
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[i].active = event.target.checked;
        return newArray;
      });
    }
  };

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleActiveAllCarriersSwitch = TempCarrierIDs => {
    const StatusArray = [];
    for (let i = 0; i < TempCarrierIDs.length; i++) {
      StatusArray.push(
        TempCarrierIDs[i].assigned !== undefined
          ? TempCarrierIDs[i].assigned
          : TempCarrierIDs[i].active
      );
    }
    const allTrue = StatusArray.every(value => value === true);
    if (allTrue) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  };
  const CheckSelectedCarriers = (CarriersArray, TempCarrierIDs) => {
    if (EditedData?.carriers) {
      const PropsCarrierArray = EditedData?.carriers;
      const temp = [...CarriersArray];
      const tempIDs = [...TempCarrierIDs];

      for (let i = 0; i < CarriersArray.length; i++) {
        const staticId = CarriersArray[i].id;
        if (
          PropsCarrierArray.some(
            element => element.id === staticId && element.active
          )
        ) {
          temp[i].active = true;
          tempIDs[i].assigned = !tempIDs[i].assigned;
        }
      }

      handleActiveAllCarriersSwitch(TempCarrierIDs);
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(temp);
    }
  };

  const handleSuccessCarriers = ApiRes => {
    setCarriersLoader(false);
    const CarriersArray = ApiRes?.data?.data;
    const TempCarrierIDs = [];
    for (let i = 0; i < CarriersArray.length; i++) {
      CarriersArray[i].active = false;
      TempCarrierIDs.push({ carrierId: CarriersArray[i].id, assigned: false });
    }
    setCarriers(CarriersArray);
    setSelectedCarriersIdArray(TempCarrierIDs);
    EditedData && CheckSelectedCarriers(CarriersArray, TempCarrierIDs);
  };

  const handleSuccessCreateAgency = ApiRes => {
    setCreateAgencyLoader(false);
    successToast(ApiRes?.data?.reasonPhrase);
    setTimeout(() => {
      history.push('/manage-agencies');
    }, 4000);
  };
  const handdleExistedUserValidation = () => {
    const errors = {};
    errors['emailError'] = 'User exist with this email try another one';
    setValidationErrors(errors);
  };

  const handleCreateUpdateAgencyReq = () => {
    setCreateAgencyLoader(true);

    const CreateOrUpdateAgencyObj = {
      id: EditedData?.id ? EditedData?.id : 0,
      name: carrierName,
      logo: agencyApiUploadedImage,
      url: '',
      active: agencyStatus,
      address: address1,
      address2: address2,
      zipCode: zip,
      city: city,
      state: state,
      country: country,
      subscriptioPlanId: 1,
      carriers: selectedCarriersIdArray,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber
    };
  };

  const handleSuccessGetAgencyDetails = ApiRes => {
    const AgencyAdminDetails = ApiRes?.data?.data?.userInfo;
    setFirstName(AgencyAdminDetails.firstName);
    setLastName(AgencyAdminDetails.lastName);
    setEmail(AgencyAdminDetails.email);
    setPhoneNumber(AgencyAdminDetails.phone);
  };

  const handleNext = index => {
    let errors = {};
    if (index === 0) {
      if (!carrierName) {
        errors['carrierNameError'] = 'Enter Carrier Name';
      } else if (carrierType.length === 0) {
        errors['carrierType'] = 'Select Carrier Type';
      } else if (!zip) {
        errors['zipError'] = 'Enter Zip';
      } else if (!city) {
        errors['cityError'] = 'Enter City';
      } else if (!state) {
        errors['stateError'] = 'Enter State or Province';
      } else if (!country) {
        errors['countryError'] = 'Enter Country';
      } else if (userLocations.length === 0) {
        errors['locationsError'] = 'Select Location';
      } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    setValidationErrors(errors);

    if (index === 1) {
      if (!firstName) {
        errors['firstNameError'] = 'Enter First Name';
      } else if (!lastName) {
        errors['lastNameError'] = 'Enter Last Name';
      }
      // else if (!designation) {
      //   errors['designation'] = 'Enter Designation';
      // }
      else if (!email) {
        errors['emailError'] = 'Enter Email';
      } else if (!phoneNumber) {
        errors['phoneNumberError'] = 'Enter Phone Number';
      } else if (phoneNumberValidError) {
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      } else if (!isValidEmail) {
        errors['emailError'] =
          'To add multiple email addresses, please separate each address with a comma';
      } else {
        // EditedData ? handleCreateUpdateAgencyReq() : ValidatUserEmail();
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
    if (index === 2) {
      const createUpdateCarrierObj = {
        agencyId: AgencyID,
        id: EditedData?.carrierId ? EditedData?.carrierId : 0,
        name: carrierName ? carrierName : '',
        icon: agencyApiUploadedImage,
        url: EditedData?.url ? EditedData?.url : '',
        cType: '',
        cSubType: '',
        active: agencyStatus,
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        email: email ? email : '',
        phone: phoneNumber ? phoneNumber : '',
        streetAddress1: address1 ? address1 : '',
        streetAddress2: address2 ? address2 : '',
        postalCode: zip ? zip : '',
        city: city ? city : '',
        country: country ? country : '',
        state: state ? state : '',
        locationIds: userLocations.map(obj => obj.id),
        designation: designation,
        cSubTypes: carrierType
      };
      if (
        (userPermissionsArr &&
          ((userPermissionsArr.filter(permissions => permissions.level3)
            .length &&
            !EditedData) ||
            (userPermissionsArr.filter(permissions => permissions.level4)
              .length &&
              EditedData))) ||
        UserIsAdmin
      ) {
        setCarriersLoader(true);
        CreateOrUpdateCarrier(createUpdateCarrierObj)
          .then(res => {
            successToast(
              EditedData
                ? 'Carrier Updated Successfully!'
                : 'Carrier Created Successfully!'
            );
            setTimeout(() => {
              history.push('/carriers', 'Non-LTL');
            }, 2000);
            setCarriersLoader(false);
          })
          .catch(err => {
            ErrorToast(
              err?.response?.data?.reasonPhrase
                ? err?.response?.data?.reasonPhrase
                : err?.message
            );
            setCarriersLoader(false);
          });
      }
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBackShipment = () => {
    setShipmentFailed(false);
    handleReset();
  };
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setActiveTabLabel(label);
  };

  const CreateAgencySteps = ['Detail', 'Contact Info', 'Review'];

  const handleCarriersSwitchOnchange = (event, SelectedApiCarrier, index) => {
    setCarriers(prevArray => {
      const newArray = [...prevArray];
      newArray[index].active = !newArray[index].active;
      handleActiveAllCarriersSwitch(newArray);
      return newArray;
    });

    for (let i = 0; i < selectedCarriersIdArray.length; i++) {
      const carrierID = selectedCarriersIdArray[i].carrierId;
      const carrierStatus = selectedCarriersIdArray[i].assigned;
      if (carrierID === SelectedApiCarrier?.id && carrierStatus) {
        selectedCarriersIdArray[i].assigned = false;
      }

      if (carrierID === SelectedApiCarrier?.id && !carrierStatus) {
        selectedCarriersIdArray[i].assigned = true;
      }
    }
    setSelectedCarriersIdArray(selectedCarriersIdArray);
  };

  const handleAgencyImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file.type.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file.type.match(imageType)) {
      reader.onloadend = () => {
        setAgencyImage(reader.result);
      };
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('imageFile', file);
      UploadCarrierLogo(AgencyID, formData)
        .then(res => {
          res.status === 200 && setAgencyApiUploadedImage(res?.data?.data);
        })
        .catch(err => ErrorToast(err?.message));
    }
  };

  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file.type.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file.type.match(imageType)) {
      reader.onloadend = () => {
        setAdminImage(reader.result);
      };

      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('file', file);

      UploadUserProfileImage(AgencyEmail, formdata)
        .then(res => {
          res.status === 200 && setCarrierAdminImageURL(res.data.data);
        })
        .catch(err => ErrorToast(err.message));
    }
  };

  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';

  const lastLetter = lastName ? lastName.charAt(0).toUpperCase() : '';

  const imageStyle = {
    marginTop: 50,
    marginLeft: 20,
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };

  const handleStepperContent = (index, label) => {
    if (index === 0) {
      const handleCarrierTypeChnage = (event, newValue) => {
        setValidationErrors({
          ...validationErrors,
          carrierType: null
        });

        setCarrierType(newValue);
      };

      const handleAgencyStatusChange = (event, newValue) => {
        setAgencyActiveValue(newValue);
        if (newValue === 'Active') {
          setAgencyStatus(true);
        } else {
          setAgencyStatus(false);
        }
      };

      const handleAdminLocationChange = (event, newValue) => {
        setUserLocations(newValue);
        setValidationErrors({
          ...validationErrors,
          locationsError: null
        });
      };

      const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
      const checkedIcon = <CheckBoxIcon fontSize='small' />;
      return (
        <Grid sx={{ height: '70vh' }}>
          <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 1 }}>
            <Grid sx={isMobile ? { height: '12vh' } : { height: '16vh' }}>
              <Grid
                className=''
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  position: 'relative'
                }}
              >
                <IconButton
                  aria-label='delete'
                  style={{ position: 'absolute', top: 1 }}
                  onClick={() => history.push('/carriers', 'Non-LTL')}
                >
                  <CloseIcon color='error' />
                </IconButton>
              </Grid>
              <div>
                <input
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  type='file'
                  accept='image/*'
                  onChange={handleAgencyImageUpload}
                  style={{ display: 'none' }}
                  id='image-upload'
                />
                <label htmlFor='image-upload'>
                  {agencyImage ? (
                    <img
                      className={isMobile ? 'Admin_Img_mobile' : 'Admin_Img'}
                      src={agencyImage}
                      alt=''
                      width='200'
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                    />
                  ) : (
                    <span
                      role='img'
                      aria-label='upload icon'
                      className={isMobile ? 'Admin_Img_mobile' : 'Admin_Img'}
                      style={
                        isMobile
                          ? {
                              display: 'inline-block',
                              borderRadius: '50%',
                              backgroundColor: '#e5e5e5',
                              textAlign: 'center',
                              lineHeight: '80px',
                              cursor: 'pointer'
                            }
                          : {
                              display: 'inline-block',
                              borderRadius: '50%',
                              backgroundColor: '#e5e5e5',
                              textAlign: 'center',
                              lineHeight: '120px',
                              cursor: 'pointer'
                            }
                      }
                    >
                      <UploadIcon />
                      Upload
                    </span>
                  )}
                </label>
              </div>
            </Grid>
          </Grid>

          <Grid sx={isMobile ? { mt: 4 } : { mt: 7 }}>
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['carrierNameError'])}
                  helperText={validationErrors['carrierNameError']}
                  onChange={e => {
                    setCarrierName(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      carrierNameError: null
                    });
                  }}
                  value={carrierName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  // sx={{ width: '20vw' }}
                  fullWidth
                  size='small'
                  label='Carrier Name'
                  type='text'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  fullWidth
                  sx={isMobile ? { mt: 1 } : { ml: 1 }}
                  size='small'
                  value={agencyActiveValue}
                  onChange={(event, newValue) =>
                    handleAgencyStatusChange(event, newValue)
                  }
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  options={options}
                  renderInput={params => (
                    <TextField
                      label='Status'
                      variant='standard'
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 13 }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                fullWidth
                disabled={
                  (userPermissionsArr &&
                    ((userPermissionsArr.filter(
                      permissions => permissions.level3
                    ).length &&
                      !EditedData) ||
                      (userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length &&
                        EditedData))) ||
                  UserIsAdmin
                    ? false
                    : true
                }
                sx={{ mt: 0 }}
                size='small'
                value={carrierType}
                options={carrierTypes}
                onChange={(event, newValue) =>
                  handleCarrierTypeChnage(event, newValue)
                }
                multiple
                getOptionLabel={option => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    required
                    label='Carrier Type'
                    error={Boolean(validationErrors['carrierType'])}
                    helperText={validationErrors['carrierType']}
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  onChange={e => setAddress1(e.target.value)}
                  value={address1}
                  fullWidth
                  // sx={{ width: '20vw' }}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  size='small'
                  label='Address Line 1'
                  type='text'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  onChange={e => setAddress2(e.target.value)}
                  value={address2}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  fullWidth
                  sx={isMobile ? { mt: 1 } : { ml: 1 }}
                  size='small'
                  label='Address Line 2'
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['zipError'])}
                  helperText={validationErrors['zipError']}
                  onChange={e => {
                    setZip(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      zipError: null
                    });
                  }}
                  value={zip}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  // sx={{ width: '20vw' }}
                  size='small'
                  label='Zip'
                  type='number'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['cityError'])}
                  helperText={validationErrors['cityError']}
                  onChange={e => {
                    const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                    (isValid || e.target.value === '') &&
                      setCity(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      cityError: null
                    });
                  }}
                  value={city}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={isMobile ? { mt: 1 } : { ml: 1 }}
                  size='small'
                  label='City'
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['stateError'])}
                  helperText={validationErrors['stateError']}
                  onChange={e => {
                    const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                    (isValid || e.target.value === '') &&
                      setState(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      stateError: null
                    });
                  }}
                  value={state}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  // sx={{ width: '20vw', mr: 0 }}
                  size='small'
                  label='State / Province'
                  type='text'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['countryError'])}
                  helperText={validationErrors['countryError']}
                  onChange={e => {
                    const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                    (isValid || e.target.value === '') &&
                      setCountry(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      countryError: null
                    });
                  }}
                  value={country}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  // sx={{ width: '20vw' }}
                  sx={isMobile ? { mt: 1 } : { ml: 1 }}
                  size='small'
                  label='Country'
                  type='text'
                />
              </Grid>
            </Grid>
            <Grid>
              {agencyLocationsLoader ? (
                <Skeleton sx={{ mt: 4 }}></Skeleton>
              ) : (
                <Autocomplete
                  disabled={
                    (userPermissionsArr &&
                      ((userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length &&
                        !EditedData) ||
                        (userPermissionsArr.filter(
                          permissions => permissions.level4
                        ).length &&
                          EditedData))) ||
                    UserIsAdmin
                      ? false
                      : true
                  }
                  multiple
                  sx={{ mt: 2 }}
                  className='w-100'
                  size='small'
                  disableClearable
                  value={userLocations}
                  onChange={(event, newValue) =>
                    handleAdminLocationChange(event, newValue)
                  }
                  renderOption={(PreviousProps, option, { selected }) => (
                    <li {...PreviousProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.name}: ${option?.city}  ${
                        option?.state
                          ? option?.state
                          : option?.stateOrProvinceCode
                      }  ${option?.postalCode} ${option?.countryCode}`}
                    </li>
                  )}
                  getOptionLabel={option =>
                    `${option?.name}: ${option?.city}  ${
                      option?.state
                        ? option?.state
                        : option?.stateOrProvinceCode
                    }  ${option?.postalCode} ${option?.countryCode}`
                  }
                  options={agencyLocations}
                  renderInput={params => (
                    <TextField
                      sx={
                        userLocations.length > 4
                          ? {
                              // maxHeight: 20,
                              height: 60,
                              padding: 1,
                              overflowY: 'scroll',
                              overflowX: 'hidden'
                            }
                          : { maxHeight: 20 }
                      }
                      required
                      multiline
                      label='Locations'
                      variant='standard'
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 13 }
                      }}
                      error={Boolean(validationErrors['locationsError'])}
                      helperText={validationErrors['locationsError']}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (index === 1) {
      const handleAdminStatusChange = (event, newValue) => {
        setAdminActiveValue(newValue);
        if (newValue === 'Active') {
          setAdminStatus(true);
        } else {
          setAdminStatus(false);
        }
      };

      const handlePhoneNumberPaste = event => {
        // Prevent the default paste behavior
        event.preventDefault();

        // Get the pasted text from clipboard
        const pastedText = event.clipboardData.getData('text/plain');

        // Remove non-numeric characters from pasted text
        const cleanedText = pastedText.replace(/\D/g, '');

        // Apply formatting logic
        const formattedValue = cleanedText.replace(
          /^(\d{3})(\d{3})(\d{4})$/,
          '($1) $2-$3'
        );
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };
      const handlePhoneNumberChange = event => {
        const inputValue = event.target.value;
        // Remove non-numeric characters and retain formatting
        const formattedValue = inputValue
          .replace(/\D/g, '')
          .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };

      const validatePhoneNumber = inputValue => {
        // Regular expression to match the USA phone number format (e.g., (123) 456-7890 or 123-456-7890)
        const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

        const isValidPhoneNumber = phoneRegex.test(inputValue);
        if (isValidPhoneNumber) {
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        }
        setIsValid(isValidPhoneNumber);
      };
      const handleEmailValidation = email => {
        const emailRegex =
          /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

        const errors = {};
        setEmail(email);

        if (emailRegex.test(email)) {
          setIsValidEmail(true);
          setValidationErrors({
            ...validationErrors,
            emailError: null
          });
        } else {
          setIsValidEmail(false);
          errors['emailError'] =
            'To add multiple email addresses, please separate each address with a comma';
        }
        setValidationErrors(errors);
      };
      if (createAgencyLoader) {
        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '60vh',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Grid>
        );
      } else
        return (
          <Grid sx={{ height: '60vh' }}>
            <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 1 }}>
              <Grid sx={{ height: 120 }}>
                <Grid
                  className=''
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    position: 'relative'
                  }}
                >
                  <IconButton
                    aria-label='delete'
                    style={{ position: 'absolute', top: 1 }}
                    onClick={() => history.push('/carriers', 'Non-LTL')}
                  >
                    <CloseIcon color='error' />
                  </IconButton>
                </Grid>
                <div>
                  <label htmlFor='image-upload'>
                    {firstName ? (
                      <img
                        src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                          firstLetter + lastLetter
                        )}</text></svg>`}
                        // src={imageUrl}
                        alt={firstLetter}
                        style={imageStyle}
                      />
                    ) : (
                      <span
                        role='img'
                        aria-label='upload icon'
                        className='Admin_Img'
                        style={{
                          display: 'inline-block',
                          borderRadius: '50%',
                          backgroundColor: '#e5e5e5',
                          textAlign: 'center',
                          lineHeight: '120px'
                          // cursor: 'pointer'
                        }}
                      >
                        {/* <UploadIcon />
                        Upload */}
                      </span>
                    )}
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid sx={{ mt: 7 }}>
              <Grid
                container
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !EditedData) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            EditedData))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['firstNameError'])}
                    helperText={validationErrors['firstNameError']}
                    onChange={e => {
                      const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                      (isValid || e.target.value === '') &&
                        setFirstName(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        firstNameError: null
                      });
                    }}
                    value={firstName}
                    required
                    // InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 } }}
                    // sx={{ width: '20vw' }}
                    size='small'
                    label='First Name'
                    type='text'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !EditedData) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            EditedData))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['lastNameError'])}
                    helperText={validationErrors['lastNameError']}
                    onChange={e => {
                      const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                      (isValid || e.target.value === '') &&
                        setLastName(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        lastNameError: null
                      });
                    }}
                    value={lastName}
                    required
                    // InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 } }}
                    sx={isMobile ? { mt: 1 } : { ml: 1 }}
                    size='small'
                    label='Last Name'
                    type='text'
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !EditedData) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            EditedData))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['designation'])}
                    helperText={validationErrors['designation']}
                    onChange={e => {
                      setDesignation(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        designation: null
                      });
                    }}
                    value={designation}
                    // required
                    // InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 } }}
                    // sx={{ width: '20vw', mr: 0 }}
                    size='small'
                    label='Designation'
                    //   type='email'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    fullWidth
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !EditedData) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            EditedData))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['phoneNumberError'])}
                    helperText={validationErrors['phoneNumberError']}
                    onChange={handlePhoneNumberChange}
                    value={phoneNumber}
                    onPaste={handlePhoneNumberPaste}
                    required
                    // InputLabelProps={{ style: { fontSize: 13 } }}
                    sx={isMobile ? { mt: 1 } : { ml: 1 }}
                    size='small'
                    label='Phone Number'
                    type='text'
                    inputProps={{
                      maxLength: 10,
                      style: { fontSize: 13 } // Set the maximum length to 10 characters
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <TextField
                    fullWidth
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !EditedData) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            EditedData))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['emailError'])}
                    helperText={validationErrors['emailError']}
                    onChange={e => handleEmailValidation(e.target.value)}
                    value={email}
                    required
                    // InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 } }}
                    // sx={{ width: '42vw', mr: 0 }}
                    size='small'
                    label='Email Address'
                    type='email'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
    }
    if (index === 2) {
      return (
        <Grid sx={{ minHeight: '60vh' }}>
          {carriersLoader ? (
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '60vh',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid sx={{ pt: '5vh' }}>
              <Grid>
                <h5 style={{ fontSize: 16 }}>Detail</h5>
                <hr />
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Name</span>
                    <br /> {carrierName}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Type</span> <br />{' '}
                    {carrierType.map(carrier => (
                      <div>{carrier}</div>
                    ))}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Carrier Status</span> <br />{' '}
                    {agencyActiveValue}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Address 1</span> <br />{' '}
                    {address1}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Address 2</span> <br />{' '}
                    {address2}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Zip</span> <br /> {zip}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>City</span> <br /> {city}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>State/Province</span> <br />{' '}
                    {state}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Country</span> <br />{' '}
                    {country}
                  </Col>
                </Row>
              </Grid>
              <Grid sx={{ mt: 5 }}>
                <h5 style={{ fontSize: 16 }}>Contact Info</h5>
                <hr />
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>First Name</span>
                    <br /> {firstName}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Last Name</span> <br />{' '}
                    {lastName}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Designation</span> <br />{' '}
                    {designation}
                  </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Phone</span> <br />{' '}
                    {phoneNumber}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <span className='Preview_Info'>Email</span>
                    <br /> {email}
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    {/* <b>Designation</b> <br /> {designation} */}
                  </Col>
                </Row>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    }
  };
  const isMobile = useIsMobile();
  return (
    <Box
      sx={
        isMobile
          ? {
              minHeight: '100vh',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              marginTop: -1
            }
          : {
              // p: 2,
              minHeight: '65vh',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              marginTop: -3
            }
      }
    >
      <ToastContainer
        position='top-center'
        autoClose={3800}
        style={
          isMobile
            ? { width: '100%', display: 'flex', justifyContent: 'center' }
            : { width: 'auto' }
        }
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Paper className='Grid_Item'>
        <Box
          sx={
            isMobile
              ? { width: '90vw', height: '100vh', marginTop: -3 }
              : { width: '42vw', marginTop: -3 }
          }
        >
          <Stepper
            // className='PickupStepperParent'
            activeStep={activeStep}
            alternativeLabel
            // nonLinear
          >
            {CreateAgencySteps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    color='inherit'
                    onClick={handleStep(index, label)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {handleStepperContent(activeStep, activeTabLabel)}
          <div className={isMobile ? 'NextBtnDiv2_mobile' : 'NextBtnDiv2'}>
            {activeStep !== 0 && (
              <Button
                // sx={{ margin: '15 10 0 0 ' }}
                fullWidth={isMobile ? true : false}
                sx={
                  isMobile
                    ? carrierTypes.length > 4 && activeStep === 0
                      ? { mt: 20 }
                      : { mt: 10 }
                    : { mt: 2 }
                }
                onClick={shipmentFailed ? handleBackShipment : handleBack}
                // className='BtnColor'
                variant='outlined'
              >
                <ChevronLeftIcon sx={{ m: 0 }} className='PrevBtnIcon' />{' '}
                Previous
              </Button>
            )}

            <Button
              disabled={carriersLoader}
              fullWidth={isMobile ? true : false}
              sx={
                isMobile
                  ? carrierTypes.length > 4 && activeStep === 0
                    ? { mt: 20 }
                    : { mt: 2 }
                  : { mt: 2, ml: 1 }
              }
              onClick={() => handleNext(activeStep)}
              className='BtnColor'
              variant='contained'
            >
              {' '}
              {activeStep === CreateAgencySteps.length - 1
                ? EditedData
                  ? 'Update'
                  : 'Create'
                : 'Continue'}
              <ChevronRightIcon sx={{ m: 0 }} className='NextBtnIcon' />
            </Button>
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
