import React, { useEffect, useRef } from 'react';
import {
  Tabs,
  Tab,
  Button,
  FormControlLabel,
  Modal,
  DialogTitle,
  IconButton,
  Chip,
  Switch,
  Skeleton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ExploreIcon from '@mui/icons-material/Explore';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import FedExLogo from '../../assets/carrier logos/fedex.png';
import FedExExpressLogo from '../../assets/carrier logos/fedexExpress.png';
import FedExPriLogo from '../../assets/carrier logos/fedex-priority.png';
import ABFLogo from '../../assets/carrier logos/ABFS.png';
import EXLALogo from '../../assets/carrier logos/EXLA.png';
import PITOHIO from '../../assets/carrier logos/PITD.png';
import CTLogo from '../../assets/carrier logos/central-transport.png';
import PYLELogo from '../../assets/carrier logos/PYLE.png';
import YRCLogo from '../../assets/carrier logos/RDWY.png';
import RLLogo from '../../assets/carrier logos/RLcarriers.png';
import WardLogo from '../../assets/carrier logos/WARD.png';
import AACTLogo from '../../assets/carrier logos/AACT.png';
import AVERITTLogo from '../../assets/carrier logos/AVRT.png';
import NEWPENLogo from '../../assets/carrier logos/New Penn.png';
import UPSLogo from '../../assets/carrier logos/Tfroce freight.png';
import UPSOldLogo from '../../assets/carrier logos/ups.png';
import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import SEFLlogo from '../../assets/carrier logos/Southeastern freight lines.png';
import ReddawayLogo from '../../assets/carrier logos/Reddaway.png';
import SAIALogo from '../../assets/carrier logos/SAIA.png';
import CNWYLogo from '../../assets/carrier logos/CNWY.png';
import GuaranteeIcon from '../../assets/guaranteed.svg';
import { useSelector } from 'react-redux';
import { GetActiveCarriers } from '../../apis/Agency';
import { useState } from 'react';
import axios from 'axios';
import { base_logo_url, base_url } from '../../apis/constants';
import { Row, Col } from 'reactstrap';
import { tooltipClasses } from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/Sort';
import './KanbanCss.css';
import { Tooltip } from '@mui/material';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { ToastContainer } from 'react-toastify';

function GetShipmentRates(props) {
  const selectedIndex = props?.selectedIndex;
  const ReloadQuote = props.ReloadQuote;
  const quoteType = props?.quote;
  const obj = props.state;
  const deepCloneObj = props.deepClone;
  const totalWeightLBS = props.totalLBSWeight;
  const SelectedRowData = props.DataToShow;
  const handleListItemClick = props.handleListItemClick;
  const [EstesApiLoading, setEstesApiLoading] = useState(false);
  const [GetRatesArray, setGetRatesArray] = useState([]);
  const [GetRateStandarArray, SetGetRateStandarArray] = useState([]);
  const [GetRateGuaranteedArray, SetGetRateGuaranteedArray] = useState([]);
  const [GetRateQuickDeliveryArray, setGetRateQuickDeliveryArray] = useState(
    []
  );
  const [finalCarrierApis, setFinalCarrierApis] = useState([]);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [data, setData] = useState([]);
  const [errorMessageApi, setErrorMessageApi] = useState([]);
  const [successRates, setSuccessRates] = useState([]);
  const [rateUniqueId, setRateUniqueId] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleShowHideErrosChnage = event => {
    setChecked(event.target.checked);
  };
  // useEffect(() => {
  //   setChecked(checkedProp);
  // }, [checkedProp]);
  const LoadingArray = [
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 },
    { item: 1 }
  ];

  const handleAllCarrierRates = validResults => {
    var temp = [];
    if (validResults?.length > 1) {
    }
    for (let i = 0; i < validResults?.length; i++) {
      setEstesApiLoading(false);
      const ShipArray = validResults;
      const originDetail = ShipArray[i]?.originDetail.contactAndAddress;
      const destinationDetail =
        ShipArray[i]?.destinationDetail.contactAndAddress;

      temp.push({
        carrierID: ShipArray[i]?.carrierInfo.id,
        carrierName: ShipArray[i]?.carrierInfo.name,
        serviceType: ShipArray[i]?.serviceType,
        quotationNumber: ShipArray[i]?.quotationNumber,
        deliveryTime: ShipArray[i]?.deliveryTime
          ? ShipArray[i]?.deliveryTime
          : null,
        originPostalCode: originDetail?.address.postalCode
          ? originDetail?.address.postalCode
          : '',
        originCity: originDetail?.address.city
          ? originDetail?.address.city
          : '',
        originStateOrProvinceCode: originDetail?.address.stateOrProvinceCode
          ? originDetail?.address.stateOrProvinceCode
          : '',
        originCountryCode: originDetail?.address.countryCode
          ? originDetail?.address.countryCode
          : '',

        originContactPersonName: originDetail?.contact?.personName,
        originContactCompanyName: originDetail?.contact?.companyName,

        originContactFaxNmb: originDetail?.contact.faxNumber,

        originContactPersonExtension: originDetail?.contact.phoneExtension,

        originContactPersonPhnNmb: originDetail?.contact.phoneNumber,
        originStreetLines: originDetail?.address.streetLines,

        destinationPostalCode: destinationDetail?.address.postalCode
          ? destinationDetail?.address.postalCode
          : '',
        destinationCity: destinationDetail?.address.city
          ? destinationDetail?.address.city
          : '',
        destinationStateOrProvinceCode: destinationDetail?.address
          .stateOrProvinceCode
          ? destinationDetail?.address.stateOrProvinceCode
          : '',
        destinationCountryCode: destinationDetail?.address.countryCode
          ? destinationDetail?.address.countryCode
          : '',
        destinationContactPersonName: destinationDetail?.contact?.personName,
        destinationContactCompanyName: destinationDetail?.contact?.companyName,
        destinationStreetLines: destinationDetail?.address.streetLines,

        destinationContactFaxNmb: destinationDetail?.contact.faxNumber,

        destinationContactPersonExtension:
          destinationDetail?.contact.phoneExtension,

        destinationContactPersonPhnNmb: destinationDetail?.contact.phoneNumber,

        totalSurcharge: ShipArray[i]?.totalSurcharge.amount,

        totalBillingWeight:
          ShipArray[i]?.totalBillingWeight.value == 0
            ? 0
            : ShipArray[i]?.totalBillingWeight.value +
                ' ' +
                ShipArray[i]?.totalBillingWeight.units !==
                null && ShipArray[i]?.totalBillingWeight.units,
        totalBaseCharge: ShipArray[i]?.totalBaseCharge.amount,
        totalCarrierCharge: ShipArray[i]?.totalCarrierCharge.amount,
        totalDistance: ShipArray[i]?.totalDistance.amount,
        totalDutiesAndTaxes: ShipArray[i]?.totalDutiesAndTaxes.amount,
        totalNetFreight: ShipArray[i]?.totalNetFreight.amount,
        totalNetChargeWithDutiesAndTaxes:
          ShipArray[i]?.totalNetChargeWithDutiesAndTaxes.amount,
        totalFreightDiscount: ShipArray[i]?.totalFreightDiscount.amount,
        netDiscount: ShipArray[i]?.netDiscount.amount,
        totalTaxes: ShipArray[i]?.totalTaxes.amount,
        surcharges: ShipArray[i]?.surcharges,
        Rate: ShipArray[i]?.totalNetCharge.amount,
        isGuaranteedDelviery: ShipArray[i]?.isGuaranteedDelviery,
        logo:
          // handleCarrierLogo(ShipArray[i].carrierInfo.name),
          ShipArray[i]?.carrierInfo?.name === 'ESTES'
            ? EXLALogo
            : ShipArray[i]?.carrierInfo?.name.trim() === 'Central Transport'
            ? CTLogo
            : ShipArray[i]?.carrierInfo?.name === 'SAIA'
            ? SAIALogo
            : ShipArray[i]?.carrierInfo?.name === 'FedEx' &&
              ShipArray[i]?.serviceType === 'FEDEX_FREIGHT_ECONOMY'
            ? FedExLogo
            : ShipArray[i]?.carrierInfo?.name === 'FedEx' &&
              ShipArray[i]?.serviceType === 'FEDEX_FREIGHT_PRIORITY'
            ? FedExPriLogo
            : ShipArray[i]?.carrierInfo.name === 'ABF'
            ? ABFLogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'Pitt Ohio'
            ? PITOHIO
            : ShipArray[i]?.carrierInfo.name.trim() === 'Aduie Pyle'
            ? PYLELogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'YRC Freight'
            ? YRCLogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'RL'
            ? RLLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'ward'
            ? WardLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'aaa cooper transportation'
            ? AACTLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'southeastern freight lines'
            ? SEFLlogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'old dominion freight line'
            ? ODFLlogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'ups'
            ? UPSLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'new penn'
            ? NEWPENLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'reddaway'
            ? ReddawayLogo
            : CNWYLogo,
        days: ShipArray[i]?.transitTime ? ShipArray[i]?.transitTime : ''
      });
    }
    setGetRatesArray(temp);

    // setGetRatesArray(temp);
  };
  const [value, setValue] = React.useState(0);

  const handleSortRatesArray = () => {
    if (value === 1) {
      const StandarArray = GetRatesArray.filter(
        items => items.isGuaranteedDelviery === false
      );
      SetGetRateStandarArray(StandarArray);
    }
    if (value === 2) {
      const GuaranteedArray = GetRatesArray.filter(
        items => items.isGuaranteedDelviery === true
      );
      SetGetRateGuaranteedArray(GuaranteedArray);
    }
    // if (value === 2) {
    //   const LowestPriceArray = GetRatesArray.sort((a, b) =>
    //     a.Rate > b.Rate ? 1 : -1
    //   );
    //   setGetRateLowestPriceArray(LowestPriceArray);
    // }
    if (value === 3) {
      const QuickestDeliveryArray = GetRatesArray.sort((c, d) =>
        c.days > d.days ? 1 : -1
      );
      setGetRateQuickDeliveryArray(QuickestDeliveryArray);
    }
  };

  useEffect(() => {
    handleSortRatesArray();
  }, [GetRatesArray, value]);

  const OriginLocation =
    obj.shipFrom.postalCode +
    ' ' +
    obj.shipFrom.city +
    ' ' +
    obj.shipFrom.stateOrProvinceCode +
    ' ' +
    obj.shipFrom.countryCode;
  const DestinationLocation =
    obj.shipTo.postalCode +
    ' ' +
    obj.shipTo.city +
    ' ' +
    obj.shipTo.stateOrProvinceCode +
    ' ' +
    obj.shipTo.countryCode;

  const OrderWeight = totalWeightLBS;
  const [originLoc, setOriginLoc] = useState(OriginLocation);
  const [destinationLoc, setDestinationLoc] = useState(DestinationLocation);
  const [requoteWeight, setRequoteWeight] = useState(OrderWeight);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  const RateErrorTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ce7a7a',
      color: 'white',
      maxWidth: 800,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    },
    [`& .${tooltipClasses.arrow}`]: {
      // backgroundColor: '#f5f5f9',
      color: '#ce7a7a',
      maxWidth: 1100,
      // width: 1900,
      // margin: '0 10 0 10 ',
      fontSize: theme.typography.pxToRem(12)
      // border: '1px solid #dadde9'
    }
  }));

  useEffect(() => {
    handleAllCarrierRates(data);
  }, [data]);

  const options = {
    // year: '2-digit',
    month: 'short',
    day: 'numeric'
  };
  // useEffect(() => {

  const uniqueIdCounterRef = useRef(1);
  const handleSuccessResponse = ApiRes => {
    setEstesApiLoading(false);

    ApiRes.data.shipmentDetails.forEach(shipmentDetail => {
      const uniqueId = uniqueIdCounterRef.current;
      uniqueIdCounterRef.current = uniqueIdCounterRef.current + 1;

      const formattedDate = new Date(shipmentDetail.deliveryTime)
        .toLocaleString('en-US', options)
        .toUpperCase();
      const yearLastTwoDigits = formattedDate.slice(-2);

      setSuccessRates(prevArray => [
        ...prevArray,
        {
          ...shipmentDetail,
          uniqueId: uniqueId,
          udeliveryTime: shipmentDetail.deliveryTime
            ? formattedDate.replace(yearLastTwoDigits, `${yearLastTwoDigits}`)
            : '',
          filterMode: !shipmentDetail.isGuaranteedDelviery
            ? 'standard'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('5')
            ? 'g5'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('10')
            ? 'g10'
            : shipmentDetail.isGuaranteedDelviery &&
              shipmentDetail.serviceType?.includes('12')
            ? 'g12'
            : 'other'
        }
      ]);
    });
  };

  const ErrMsgArray = [
    { title: 'CTQuoteRate', msg: '', logo: CTLogo },
    {
      title: 'FedEx',
      msg: '',
      logo:
        props?.state?.shipmentType === 'Parcel' ? FedExExpressLogo : FedExLogo
    },
    { title: 'FedExParcelQuoteRate', msg: '', logo: FedExExpressLogo },
    { title: 'XPO', msg: '', logo: CNWYLogo },
    { title: 'Estes', msg: '', logo: EXLALogo },
    { title: 'SAIA', msg: '', logo: SAIALogo },
    { title: 'ABF', msg: '', logo: ABFLogo },
    { title: 'PittOhio', msg: '', logo: PITOHIO },
    { title: 'Sefl', msg: '', logo: SEFLlogo },
    { title: 'NewPenn', msg: '', logo: NEWPENLogo },
    { title: 'ODFL', msg: '', logo: ODFLlogo },
    {
      title: 'UPS',
      msg: '',
      logo: props?.state?.shipmentType === 'Parcel' ? UPSOldLogo : UPSLogo
    },
    {
      title: 'YRC',
      msg: '',
      logo: YRCLogo
    },
    { title: 'Reddaway', msg: '', logo: ReddawayLogo },
    { title: 'Ward', msg: '', logo: WardLogo },
    { title: 'RL', msg: '', logo: RLLogo },
    { title: 'AduiePyle', msg: '', logo: PYLELogo },
    { title: 'AAACooper', msg: '', logo: AACTLogo },
    { title: 'Averitt', msg: '', logo: AVERITTLogo }
  ];
  let tempErr = [];
  // let tempErr = [...errorMessageApi];
  const handleRejectRepsone = err => {
    setEstesApiLoading(false);

    const ErrName = err?.response?.request?.responseURL?.split('/');
    const LastName = ErrName[ErrName?.length - 1];
    const errorMsgPair = {
      message: err?.response?.data
    };

    const ErrObj = {
      message:
        err?.response?.data.length > 2
          ? err?.response?.data
          : JSON.stringify(errorMsgPair, null, 2),
      URL: err?.response?.request?.responseURL,
      logo: ErrMsgArray.find(items =>
        LastName.includes(items.title) ? items.logo : null
      )
    };

    tempErr.push({
      message: err?.response?.data,
      URL: err?.response?.request?.responseURL
    });
    if (tempErr.length === finalCarrierApis.length) {
      setChecked(true);
    }
    setErrorMessageApi(prevArray => [...prevArray, ErrObj]);

    setEstesApiLoading(false);
  };
  const fetchData = async (RequoteObj, DeepCloneRequoteObj) => {
    const GetAuthToken = () => {
      return localStorage.getItem('UserAuthToken');
    };

    const AgencyLoginHeader = axios.create({
      baseURL: base_url,
      responseType: 'json'
    });

    // Add an interceptor to update the Authorization header before each request
    AgencyLoginHeader.interceptors.request.use(
      async config => {
        const authToken = GetAuthToken();
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    setData([]);
    setSuccessRates([]);
    setRateUniqueId(0);
    setGetRatesArray([]);
    setErrorMessageApi([]);
    if (RequoteObj !== undefined) {
      setOriginLoc(
        RequoteObj.shipFrom.city +
          ' ' +
          RequoteObj.shipFrom.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipFrom.postalCode
      );
      setDestinationLoc(
        RequoteObj.shipTo.city +
          ' ' +
          RequoteObj.shipTo.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipTo.postalCode
      );
      setRequoteWeight(RequoteObj?.commodityInfo[0]?.weight?.value);
    }
    setEstesApiLoading(true);
    let temp = RequoteObj !== undefined ? [] : [...data];
    for (let i = 0; i < finalCarrierApis.length; i++) {
      const EndPoint = finalCarrierApis[i];
      RequoteObj !== undefined
        ? AgencyLoginHeader.post(EndPoint, deepCloneObj)
            .then(res => res.status === 200 && handleSuccessResponse(res))
            .catch(err => handleRejectRepsone(err))
        : AgencyLoginHeader.post(EndPoint, deepCloneObj)
            .then(res => res.status === 200 && handleSuccessResponse(res))
            .catch(err => handleRejectRepsone(err));
    }
  };

  const handleGetCarrierNames = data => {
    let tempApis = [];
    const CarrierArray = data.filter(
      items =>
        items.active &&
        (props?.state?.shipmentType === 'Parcel'
          ? items.cType === 'Parcel'
          : items.cType === 'LTL')
    );
    for (let i = 0; i < CarrierArray.length; i++) {
      const CarrierName = CarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      tempApis.push(element);
      // tempApis.push(element.includes('YRC') ? element : null);
    }
    setFinalCarrierApis(tempApis);
    // getPrice();
  };
  // useEffect(() => {
  // }, []);
  useEffect(() => {
    setEstesApiLoading(true);
    fetchData();
  }, [finalCarrierApis]);

  const CallCarrierRatesApi = () => {
    GetActiveCarriers(AgencyID, props.DataToShow.locationId)
      .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
      .catch(err => console.log('Error here...', err));
  };

  useEffect(() => {
    CallCarrierRatesApi();
  }, [ReloadQuote]);

  const [filterKey, setFilterKey] = useState('*');
  const [sortKey, setSortKey] = useState('price');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [detailModal, setDetailModal] = useState(false);
  const [terminalModal, setTerminalModal] = useState(false);
  const [filterValue, setFilterValue] = useState(0);
  const [sortValue, setSortValue] = useState(0);
  const [currentRate, setCurrentRate] = useState({});

  const handleFilterValueChange = (event, newValue) => {
    setFilterValue(newValue);
  };
  const handleSortValueChange = (event, newValue) => {
    setSortValue(newValue);
  };

  const handleSortToggle = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };
  const applyFilter = item => {
    switch (filterKey) {
      case '*':
        return true;
      case 'g5':
        return item.filterMode === 'g5';
      case 'g10':
        return item.filterMode === 'g10';
      case 'g12':
        return item.filterMode === 'g12';
      case 'standard':
        return item.filterMode === 'standard';
      case 'guaranteed':
        return item.isGuaranteedDelviery;
      default:
        return false;
    }
  };

  const applySort = (rowA, rowB) => {
    switch (sortKey) {
      case 'price':
        return sortOrder === 'ASC'
          ? rowA.totalNetCharge.amount - rowB.totalNetCharge.amount
          : rowB.totalNetCharge.amount - rowA.totalNetCharge.amount;
      case 'days':
        const daysA = isNaN(parseInt(rowA.transitTime))
          ? 9999
          : parseInt(rowA.transitTime);
        const daysB = isNaN(parseInt(rowB.transitTime))
          ? 9999
          : parseInt(rowB.transitTime);
        return sortOrder === 'ASC' ? daysA - daysB : daysB - daysA;
      case 'name':
        return sortOrder === 'ASC'
          ? rowA.carrierInfo.name.localeCompare(rowB.carrierInfo.name)
          : rowB.carrierInfo.name.localeCompare(rowA.carrierInfo.name);
      default:
        return 0; // Default case: no sorting
    }
  };

  const applySubChargesSort = (rowA, rowB) => {
    return rowA.order - rowB.order;
  };

  const handleViewRate = (event, rate) => {
    event.stopPropagation();
    setCurrentRate(rate);
    setDetailModal(true);
  };

  const handleViewRate1 = (event, rate) => {
    event.stopPropagation();
    setCurrentRate(rate);
    setTerminalModal(true);
  };

  const handleKanbanCardClicked = (id, rate) => {
    // setCurrentKanabn(id);
    // setRateToBook(rate);
    console.log(rate, 'rateraterate');
    handleListItemClick(
      {
        carrierName: rate.carrierInfo.name,
        carrierID: rate.carrierInfo.id,
        quotationNumber: rate.quotationNumber,
        totalNetCharge: rate.totalNetCharge.amount,
        serviceType: rate.serviceType,
        deliveryTime: rate.deliveryTime
      },
      id
    );
  };

  const formatePrice = price => {
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white',
    maxHeight: '90vh',
    overflowY: 'scroll'
  };

  return (
    <div style={{ width: '80%', height: '75vh' }}>
      <ToastContainer
        style={
          // isMobile
          //   ? { width: '100%', display: 'flex', justifyContent: 'center' }
          // :
          { width: 'auto' }
        }
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Fragment>
        <Modal
          open={terminalModal}
          onClose={() => setTerminalModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          scroll='paper'
        >
          <Box
            className=' py-3 d-flex flex-column detail-bottom-drawer'
            style={style}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                onClick={() => setTerminalModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div
              className=' py-3 mt-4 d-flex flex-column '
              style={{ width: '97%' }}
            >
              <Row
                className='px-3 d-flex flex-row'
                style={{ marginTop: '-30', marginBottom: -10 }}
              >
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className=''>
                    <img
                      alt={'st'}
                      src={`${base_logo_url}carrier_logos/${currentRate?.carrierInfo?.logo}`}
                      width={90}
                    />
                  </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='d-block'>
                    <Typography
                      variant='h4'
                      className='fw-bold'
                      style={{ textAlign: 'center' }}
                    >
                      <span className='dayss'>
                        {isNaN(parseInt(currentRate.transitTime))
                          ? 'N/A'
                          : currentRate.transitTime === '0'
                          ? 'N/A'
                          : parseInt(currentRate.transitTime)}
                      </span>
                    </Typography>
                    <Typography
                      variant='body1'
                      className='text-black'
                      style={{ fontSize: '14px', textAlign: 'center' }}
                    >
                      Service Day(s)
                    </Typography>
                  </div>
                </Col>
              </Row>

              <Row className='px-3 d-flex flex-row ' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Origin Terminal Info
                    </Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Address
                    </Typography>
                    <Typography
                      // onClick={() => getRandomAddress()}
                      variant='h5'
                      className='info-label-value'
                    >{`${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .streetLines
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .streetLines
                        : ' '
                    }`}</Typography>
                    <Typography
                      // onClick={() => handleOpenMap(currentRate?.originDetail)}
                      variant='h5'
                      className='info-label-value'
                    >{`${
                      currentRate?.originDetail?.contactAndAddress?.address.city
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .city + ','
                        : ' '
                    } ${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .stateOrProvinceCode
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .stateOrProvinceCode + ','
                        : ''
                    } ${
                      currentRate?.originDetail?.contactAndAddress?.address
                        .postalCode
                        ? currentRate?.originDetail?.contactAndAddress?.address
                            .postalCode
                        : ''
                    }`}</Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Phone
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      <PhoneAndroidOutlinedIcon
                        style={{
                          height: 18,
                          width: 18
                        }}
                      />
                      {currentRate?.originDetail?.contactAndAddress?.contact
                        .phoneNumber ? (
                        <a
                          className='call-phone-number'
                          href={`tel:${currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber}`}
                        >
                          {currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber.replace(
                            /\D/g,
                            ''
                          ).length < 11
                            ? currentRate?.originDetail?.contactAndAddress?.contact.phoneNumber
                                .replace(/\D/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                            : currentRate?.originDetail?.contactAndAddress
                                ?.contact.phoneNumber}
                        </a>
                      ) : (
                        ' '
                      )}
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Destination Terminal Info
                    </Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Address
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .streetLines
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.streetLines
                        : ' '
                    }`}</Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .city
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.city + ','
                        : ' '
                    } ${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .stateOrProvinceCode
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.stateOrProvinceCode + ','
                        : ''
                    } ${
                      currentRate?.destinationDetail?.contactAndAddress?.address
                        .postalCode
                        ? currentRate?.destinationDetail?.contactAndAddress
                            ?.address.postalCode
                        : ''
                    }`}</Typography>
                    <Typography variant='body1' className=' fw-bold' style={{}}>
                      Phone
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      <PhoneAndroidOutlinedIcon
                        style={{
                          height: 18,
                          width: 18
                        }}
                      />
                      {currentRate?.destinationDetail?.contactAndAddress
                        ?.contact.phoneNumber ? (
                        <a
                          className='call-phone-number'
                          href={`tel:${currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber}`}
                        >
                          {currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber.replace(
                            /\D/g,
                            ''
                          ).length < 11
                            ? currentRate?.destinationDetail?.contactAndAddress?.contact.phoneNumber
                                .replace(/\D/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                            : currentRate?.destinationDetail?.contactAndAddress
                                ?.contact.phoneNumber}
                        </a>
                      ) : (
                        ' '
                      )}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Box>
        </Modal>

        <Modal
          open={detailModal}
          onClose={() => setDetailModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          scroll='paper'
        >
          <Box
            className=' py-3 d-flex flex-column detail-bottom-drawer'
            style={style}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <IconButton
                onClick={() => setDetailModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div
              className=' py-3 mt-4 d-flex flex-column '
              style={{ width: '97%' }}
            >
              <Row
                className='px-3 d-flex flex-row'
                style={{ marginTop: '-30', marginBottom: -10 }}
              >
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className=''>
                    <img
                      alt={'st'}
                      src={`${base_logo_url}carrier_logos/${currentRate?.carrierInfo?.logo}`}
                      width={90}
                    />
                  </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <div className='d-block'>
                    <Typography
                      variant='h4'
                      className='fw-bold'
                      style={{ textAlign: 'center' }}
                    >
                      <span className='dayss'>
                        {isNaN(parseInt(currentRate.transitTime))
                          ? 'N/A'
                          : currentRate.transitTime === '0'
                          ? 'N/A'
                          : parseInt(currentRate.transitTime)}
                      </span>
                    </Typography>
                    <Typography
                      variant='body1'
                      className='text-black'
                      style={{ fontSize: '14px', textAlign: 'center' }}
                    >
                      Service Day(s)
                    </Typography>
                  </div>
                </Col>
              </Row>

              <Row className='px-3 d-flex flex-row ' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=' '
                      style={{ color: '#1565c0' }}
                    >
                      Origin
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {quoteType === 'Quick Quote'
                        ? SelectedRowData?.shipFrom?.streetLines
                        : `${SelectedRowData?.originCity} ${SelectedRowData?.originState} ${SelectedRowData?.originZipCode} ${SelectedRowData?.originCountry}`}
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=' '
                      style={{ color: '#1565c0' }}
                    >
                      Destination
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>
                      {quoteType === 'Quick Quote'
                        ? SelectedRowData?.shipTo?.streetLines
                        : `${SelectedRowData?.destinationCity} ${SelectedRowData?.destinationState} ${SelectedRowData?.destinationZipCode} ${SelectedRowData?.destinationCountry}`}
                    </Typography>
                  </div>
                </Col>
              </Row>

              <Row className='px-3 d-flex flex-row ' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Weight
                    </Typography>
                    <Typography variant='h5' className='info-label-value'>{`${
                      quoteType === 'Quick Quote'
                        ? SelectedRowData?.commodityInfo?.[0]?.weight?.value
                        : SelectedRowData?.details[0]?.netWeight
                    } lbs`}</Typography>
                  </div>
                </Col>
                {props?.state?.shipmentType !== 'Parcel' && (
                  <Col>
                    <div className='my-2'>
                      <Typography
                        variant='h6'
                        className=' '
                        style={{ color: '#1565c0' }}
                      >
                        Class
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>{`${
                        quoteType === 'Quick Quote'
                          ? SelectedRowData?.commodityInfo?.[0]?.freightClass
                          : SelectedRowData?.details[0]?.freightClass
                      }`}</Typography>
                    </div>
                  </Col>
                )}
              </Row>
              {props?.state?.shipmentType !== 'Parcel' && (
                <Row className='px-3 d-flex flex-row' style={{}}>
                  <Col>
                    <div className='my-2'>
                      <Typography
                        variant='h6'
                        className=''
                        style={{ color: '#1565c0' }}
                      >
                        Quote ID
                      </Typography>
                      <Typography variant='h5' className='info-label-value'>
                        {currentRate?.quotationNumber}
                      </Typography>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className='px-3 d-flex flex-row' style={{}}>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Charges Detail
                    </Typography>
                    {currentRate?.surcharges
                      ?.sort(applySubChargesSort)
                      .map(surcharge => (
                        <Typography
                          variant='h5'
                          className='info-label-value'
                        >{`${surcharge.description}`}</Typography>
                      ))}

                    <Typography
                      variant='body1'
                      className=' fw-bold my-1'
                      style={{ textDecoration: 'underline' }}
                    >
                      Total Price
                    </Typography>
                  </div>
                </Col>
                <Col>
                  <div className='my-2'>
                    <Typography
                      variant='h6'
                      className=''
                      style={{ color: '#1565c0' }}
                    >
                      Charges
                    </Typography>
                    {currentRate?.surcharges
                      ?.sort(applySubChargesSort)
                      .map(surcharge => (
                        <Typography variant='h5' className='info-label-value'>
                          {surcharge?.description?.includes('%')
                            ? `${surcharge.amount?.amount.toFixed(2)}%`
                            : formatePrice(surcharge.amount?.amount)}
                        </Typography>
                      ))}
                    <Typography
                      variant='body1'
                      className=' fw-bold my-1'
                      style={{ textDecoration: 'underline' }}
                    >
                      ${currentRate?.totalNetCharge?.amount.toFixed(2)}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Box>
        </Modal>
      </React.Fragment>

      <div
        className='d-flex flex-row mx-2'
        style={{ justifyContent: 'space-between' }}
      >
        <Box className='d-flex mb-2' sx={{ alignItems: 'center' }}>
          {/* <Typography variant="h6" className="fw-bold text-black me-2" style={{ cursor: 'pointer', fontSize: '13px' }} ><FilterAltIcon style={{ fontSize: '16px' }} /> Filter</Typography> */}
          <Tabs
            onChange={handleFilterValueChange}
            value={filterValue}
            aria-label='Tabs where selection follows focus'
            selectionFollowsFocus
          >
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              onClick={() => setFilterKey('*')}
              label='All'
            />
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              onClick={() => setFilterKey('standard')}
              label='Standard'
            />
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              onClick={() => setFilterKey('guaranteed')}
              label='Guaranteed'
            />
            {/* <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('g10')} label="10:00 - 10:30 AM" />
            <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('g12')} label="12:00 PM" />
            <Tab sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }} className="px-2 fw-bold" onClick={() => setFilterKey('g5')} label="05:00 PM" /> */}
          </Tabs>
        </Box>
        <Box className='d-flex' sx={{ alignItems: 'center' }}>
          <Typography
            variant='h6'
            className='fw-bold text-black me-2'
            style={{ cursor: 'pointer', fontSize: '13px' }}
            onClick={handleSortToggle}
          >
            {sortOrder === 'ASC' ? (
              <SortIcon
                style={{ transform: 'rotateX(180deg)', fontSize: '16px' }}
              />
            ) : (
              <SortIcon style={{ fontSize: '16px' }} />
            )}{' '}
            Sort
          </Typography>
          <Tabs
            onChange={handleSortValueChange}
            value={sortValue}
            aria-label='Tabs where selection follows focus'
            selectionFollowsFocus
          >
            {/* icon={sortOrder === 'ASC' ? <SortIcon style={{ transform: 'rotateX(180deg)' }} /> : <SortIcon />} */}
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              iconPosition='start'
              label='Price'
              onClick={() => setSortKey('price')}
            />
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              iconPosition='start'
              label='Days'
              onClick={() => setSortKey('days')}
            />
            <Tab
              sx={{ fontSize: '13px', minWidth: 'auto', textTransform: 'none' }}
              className='px-2 fw-bold'
              iconPosition='start'
              label={sortOrder === 'ASC' ? 'Carrier A-Z' : 'Carrier Z-A'}
              onClick={() => setSortKey('name')}
            />
          </Tabs>
        </Box>
      </div>
      <div
        className='d-flex flex-row'
        style={{
          justifyContent: 'flex-end',
          marginTop: '-6px',
          marginBottom: '-6px'
        }}
      >
        <Chip
          sx={{ mt: 1, ml: 1 }}
          // variant='outlined'
          label={
            <FormControlLabel
              onChange={e => console.log(e, 'swithchange')}
              control={
                <Switch
                  checked={checked}
                  onChange={handleShowHideErrosChnage}
                  // color='error'
                  size='small'
                />
              }
              label={
                <Typography variant='body2' sx={{ fontSize: '8px' }}>
                  {checked ? 'Hide invalid carriers' : 'Show invalid carriers'}
                </Typography>
              }
            />
          }
        />
      </div>

      <div className='rates-data-container'>
        {EstesApiLoading ? (
          LoadingArray.map(items => (
            <div className='Error_Carrier_div_LTL'>
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div
                  style={
                    EstesApiLoading
                      ? {
                          width: 60,
                          fontSize: 12,
                          fontWeight: 700,
                          position: 'absolute',
                          margin: '-90 0 0 20',
                          backgroundColor: 'white',
                          whiteSpace: 'nowrap'
                        }
                      : {
                          width: 'auto',
                          fontSize: 12,
                          fontWeight: 700,
                          position: 'absolute',
                          margin: '-90 0 0 20',
                          backgroundColor: 'white',
                          whiteSpace: 'nowrap'
                        }
                  }
                ></div>
                <Col lg={3}>
                  <Box
                    sx={{
                      height: 60,
                      margin: '20 0 0 17'
                    }}
                  >
                    <Skeleton variant='circular' width={40} height={40} />
                  </Box>
                </Col>
                <Col lg={2}>
                  <div style={{ cursor: 'pointer' }}>
                    <h2>
                      <Box sx={{ width: 120 }}>
                        <Skeleton />
                      </Box>
                    </h2>

                    <span
                      style={{
                        width: 'auto',
                        fontSize: 10,
                        fontWeight: 700,
                        position: 'absolute',
                        margin: '-14 0 0 0',
                        cursor: 'auto'
                      }}
                    >
                      <Box sx={{ width: 100, marginTop: 0.5 }}>
                        <Skeleton />
                      </Box>
                    </span>
                  </div>
                </Col>
                <Col lg={3}>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 15
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 700
                        }}
                      >
                        {value === 1 ? (
                          'DELIVERY DATE'
                        ) : (
                          <Box sx={{ width: 110 }}>
                            <Skeleton />
                          </Box>
                        )}
                        <br />
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 'normal'
                          }}
                        >
                          <Box sx={{ width: 80, marginTop: -2 }}>
                            <Skeleton />
                          </Box>
                        </span>
                      </span>
                    </div>{' '}
                    <div>
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          margin: '0 0 0 18'
                        }}
                      >
                        {value === 1 ? (
                          'DELIVERY TIME'
                        ) : EstesApiLoading ? (
                          <Box sx={{ width: 110, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                        ) : (
                          'EST DELIVERY TIME'
                        )}
                        <br />
                        <span
                          style={{
                            fontSize: 11,
                            fontWeight: 'normal',
                            margin: '0 0 0 18'
                          }}
                        >
                          <Box sx={{ width: 80, marginTop: -2, marginLeft: 3 }}>
                            <Skeleton />
                          </Box>
                        </span>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <span
                      style={{
                        fontSize: 11,
                        fontWeight: 700,
                        margin: '0 0 0 18'
                      }}
                    >
                      {value === 1 ? (
                        'DELIVERY TIME'
                      ) : EstesApiLoading ? (
                        <Box sx={{ width: 110, marginLeft: 3 }}>
                          <Skeleton />
                        </Box>
                      ) : (
                        'EST DELIVERY TIME'
                      )}
                      <br />
                      <span
                        style={{
                          fontSize: 11,
                          fontWeight: 'normal',
                          margin: '0 0 0 18'
                        }}
                      >
                        <Box sx={{ width: 80, marginTop: -2, marginLeft: 3 }}>
                          <Skeleton />
                        </Box>
                      </span>
                    </span>
                  </div>{' '}
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <>
            {successRates
              .filter(applyFilter)
              .sort(applySort)
              .map((item, index) => (
                <div
                  key={index}
                  className={`filter-item ${item.filterMode} p-2`}
                  style={{ width: '100%' }}
                >
                  <Row
                    key={item.uniqueId}
                    id={item.uniqueId}
                    className={
                      selectedIndex === item.uniqueId
                        ? 'table-card active'
                        : 'table-card'
                    }
                    onClick={() => handleKanbanCardClicked(item.uniqueId, item)}
                  >
                    <span className='namee d-none'>
                      {item.carrierInfo.name}
                    </span>
                    <Col
                      className='d-flex flex-row h-100'
                      style={{ alignItems: 'center' }}
                    >
                      {/* <Checkbox
                        style={{
                          opacity: 0,
                          pointerEvents: 'none',
                          marginRight: -10,
                          marginLeft: -10
                        }}
                        className='kanban-selectbox'
                        checked={selectedIndex === item.uniqueId ? true : false}
                        icon={
                          <RadioButtonUncheckedIcon
                            style={{ fontSize: '0.8rem' }}
                          />
                        }
                        checkedIcon={
                          <CheckCircleIcon style={{ fontSize: '0.8rem' }} />
                        }
                      /> */}

                      <img
                        alt=''
                        className='table-card-logo'
                        src={`${base_logo_url}carrier_logos/${item?.carrierInfo?.logo}`}
                        width={90}
                      />
                    </Col>

                    <Col>
                      <Typography variant='h4' className='fw-bold' style={{}}>
                        {formatePrice(item.totalNetCharge.amount)}
                      </Typography>
                      {/* {item.isGuaranteedDelviery && ( */}
                      <Typography
                        variant='body1'
                        className='text-black'
                        style={{ fontSize: '13px' }}
                      >
                        {/* {item.udeliveryTime === 'INVALID DATE'
                          ? ''
                          : item.udeliveryTime}{' '} */}
                        {item.serviceType}
                        <br />
                        {item.deliveryTime}
                      </Typography>
                      {/* )} */}
                    </Col>

                    <Col>
                      <Typography
                        variant='h4'
                        className='fw-bold'
                        style={{ textAlign: 'center' }}
                      >
                        <span className='dayss'>
                          {isNaN(parseInt(item.transitTime))
                            ? 'N/A'
                            : item.transitTime === '0'
                            ? 'N/A'
                            : parseInt(item.transitTime)}
                        </span>
                      </Typography>
                      <Typography
                        variant='body1'
                        className='text-black fw-bold'
                        style={{ fontSize: '13px', textAlign: 'center' }}
                      >
                        Service Day(s)
                      </Typography>
                    </Col>

                    <Col>
                      <Button
                        variant='text'
                        startIcon={<RemoveRedEyeIcon />}
                        onClick={event => handleViewRate(event, item)}
                        className='fw-bold'
                        style={{
                          textTransform: 'none',
                          textDecoration: 'underline',
                          fontSize: '13px'
                        }}
                      >
                        Quote Detail
                      </Button>
                    </Col>
                    {props?.state?.shipmentType !== 'Parcel' && (
                      <Col style={{ position: 'relative' }}>
                        <Button
                          variant='text'
                          startIcon={<ExploreIcon />}
                          onClick={event => handleViewRate1(event, item)}
                          className='fw-bold'
                          style={{
                            textTransform: 'none',
                            textDecoration: 'underline',
                            fontSize: '13px'
                          }}
                        >
                          Terminal Info
                        </Button>
                        {item.isGuaranteedDelviery && (
                          <img
                            alt=''
                            style={{ position: 'absolute', right: 8, top: -10 }}
                            src={GuaranteeIcon}
                            width={45}
                            height={45}
                          />
                        )}
                      </Col>
                    )}
                    {/* <Col xs={1}>
                      
                    </Col> */}

                    {/* <Col>
                <Button variant="contained" startIcon={<CheckCircleIcon />} onClick={() => handleBookShipmentClicked(item)}>
                  Book Shipment
                </Button>
              </Col> */}
                  </Row>
                </div>
              ))}

            {checked &&
              errorMessageApi?.map((item, index) => (
                <div
                  className='filter-item errs  p-2'
                  style={{ width: '100%' }}
                >
                  <Row className='table-card'>
                    <Col
                      xs={2}
                      className='d-flex flex-row h-100'
                      style={{ alignItems: 'center' }}
                    >
                      <img
                        alt=''
                        className='table-card-logo'
                        src={item?.logo?.logo}
                        width={90}
                      />
                    </Col>
                    <Col
                      className='d-flex flex-row h-100'
                      style={{ alignItems: 'center' }}
                    >
                      <RateErrorTooltip
                        title={item.message.length > 90 ? item.message : ''}
                        placement='top'
                        arrow
                        style={{ backgroundColor: '#c21919', color: 'white' }}
                      >
                        <Typography
                          variant='h6'
                          className=' text-white px-2 py-1'
                          style={{
                            fontSize: '13px',
                            backgroundColor: '#c21919',
                            borderRadius: '6px'
                          }}
                        >
                          {item.message.substring(0, 100)}
                        </Typography>
                      </RateErrorTooltip>
                    </Col>
                  </Row>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(GetShipmentRates);
