import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  GetCarriers,
  UpdateCarrier,
  UpdateCarrierDetails,
  UpdateCarrierProSeries,
  UpdateCarrierRateQuoteSettings,
  UpdateCarrierSurcharge,
  UpdateCarrierThirdPartyInfo
} from '../../apis/Agency';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './Carriers.css';
import { GetCarrierDetails } from '../../apis/Agency';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer, useToast } from 'react-toastify';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { GarageOutlined } from '@mui/icons-material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NoLogo from '../../assets/carrier logos/No Logo.png';
import { multiSectionDigitalClockClasses } from '@mui/x-date-pickers';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MetaTags } from 'react-meta-tags';
import { base_logo_url, base_url } from '../../apis/constants';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';

function Carriers(props) {
  const [proNumberSuffixPosition, setProNumbersuffixPosition] = useState('Pre');
  const [surchargeType, setSurchargeType] = useState('%');
  const [paymentTerms, setPaymentTerms] = useState('Inbound');
  const [ratesType, setRatesType] = useState('All');
  const [guaranteedType, setGuaranteedType] = useState('All');
  const [secondPaymentTerms, setSecondPaymentTerms] = useState('Inbound');
  const [secondRatesType, setSecondRatesType] = useState('All');
  const [secondGuaranteedType, setSecondGuaranteedType] = useState('All');
  const [surchargeValue, setSurchargeValue] = useState('');
  const [proNumberSuffixValue, setProNumberSuffixValue] = useState('');
  const [proNumberFrom, setProNumberFrom] = useState('');
  const [proNumberTo, setProNumberTo] = useState('');
  const [proNumberAlert, setProNumberAlert] = useState('');
  const [proNumberLeft, setProNumberLeft] = useState('');
  const [proNumberTotals, setProNumberTotals] = useState('');
  const [pronmbsApiData, setPronmbsApiData] = useState(null);

  const handleChangeProNmb = event => {
    setProNumbersuffixPosition(event.target.value);
  };
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const history = useHistory();
  const [carriersLoader, setCarriersLoader] = useState(true);
  const [carriers, setCarriers] = useState([]);
  const [TLcarriers, setTLCarriers] = useState([]);
  const [LTLCarriers, setLTLCarriers] = useState([]);
  const [parcelCarriers, setParcelCarriers] = useState([]);
  const [configuredCarrierName, setConfiguredCarrierName] = useState('');
  const [configuredCarrierArray, setConfiguredCarrierArray] = useState([]);
  const [configuredCarrierLoader, setConfiguredCarrierLoader] = useState(false);
  const [validattionErrors, setValidattionErrors] = useState({});
  const [FinalConfigureCarrierArray, setFinalConfigureCarrierArray] =
    useState(null);
  const [carrierID, setCarrierID] = useState('');
  const [thirdPatyAccDetails, setThirdPatyAccDetails] = useState({
    id: 0,
    status: true,
    thirdPartyAccountNumber: '',
    companyName: '',
    streetLines: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: ''
  });
  const [carrierStatus, setCarrierStatus] = useState('');
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [createUpdateCarrier, setCreateUpdateCarrier] = useState(false);
  const [carrierTabValue, setCarrierTabValue] = useState('1');
  var filteredCarrierPermissions;

  // useEffect(() => {
  //   setThirdPatyAccDetails({ ...thirdPatyAccDetails, id: carrierID });
  // }, [carrierID]);
  const isMobile = useIsMobile();
  useEffect(() => {
    var filteredCarrierPermissions2 = userRolePermissions?.filter(items => {
      return items.permissionName === 'Carriers';
    });
    filteredCarrierPermissions = filteredCarrierPermissions2;
    setUserPermissionsArr(filteredCarrierPermissions2);
    if (createUpdateCarrier) {
      setColumnsData(mncolumns);
    }
  }, [userRolePermissions, carriers]);

  const handleSuccesGetCarriers = (ApiRes, type) => {
    const CarrierArray = ApiRes?.data?.data;
    setCarriersLoader(false);
    const LTL = CarrierArray.filter(items => items.cType === 'LTL');
    const TL = CarrierArray.filter(items => items.cType === 'TL').reverse();
    const Parcels = CarrierArray.filter(
      items => items.cType === 'Parcel'
    ).reverse();
    setCarriers(LTL);
    setLTLCarriers(LTL);
    setTLCarriers(TL);
    setParcelCarriers(Parcels);
    if (props?.location?.state === 'Non-LTL') {
      setCarriers(TL);
    }
    if (type === 'LTL') {
      setCarriers(LTL);
    }
    if (value === '3') {
      setCarriers(Parcels);
    }
  };
  useEffect(() => {
    GetCarriers(AgencyID)
      .then(res => res.status === 200 && handleSuccesGetCarriers(res))
      .catch(err => console.log(err, 'err'));
  }, []);
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [proNumberErrors, setProNumbersErrors] = useState({});
  const [proNmbChanged, setProNmbChanged] = useState(false);

  const handleConfirmationCarrierSettins = () => {
    let proNumberErrors = {};
    // if (!proNumberFrom) {
    //   proNumberErrors['proNumberFrom'] = 'Enter starting pro-number';
    //   setProNumbersErrors(proNumberErrors);
    // } else if (!proNumberTo) {
    //   proNumberErrors['proNumberTo'] = 'Enter ending pro-number';
    //   setProNumbersErrors(proNumberErrors);
    // }
    // else
    if (
      proNumberFrom &&
      proNumberTo &&
      parseInt(proNumberTo) < parseInt(proNumberFrom)
    ) {
      // setProNumberTo('');
      setProNumbersErrors({
        ...proNumberErrors,
        proNumberTo: 'ending pro-number cannot be less than starting pronumber'
      });
    } else if (proNumberFrom && proNumberTo && !proNumberAlert) {
      proNumberErrors['proNumberAlert'] = 'Enter minimum pro-number for Alert';
      setProNumbersErrors(proNumberErrors);
    } else setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };
  const handleClose = () => {
    // setSecondPaymentTerms('Inbound');
    setConfiguredCarrierArray([]);
    setOpen(false);
    setValidattionErrors({});
    setCarrierID('');
    setCarrierStatus('');
    setProNumbersuffixPosition('Prefix');
    setProNumberSuffixValue('');
    setProNumberFrom('');
    setProNumberTo('');
    setProNumberAlert('');
    setProNumberLeft('');
    setProNumberTotals('');
    setSurchargeType('%');
    setSurchargeValue('');
    setThirdPatyAccDetails({
      id: 0,
      status: true,
      thirdPartyAccountNumber: '',
      companyName: '',
      streetLines: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: ''
    });
    setConfiguredCarrierName('');
    setProNumbersErrors({});
  };

  const handleUpdateCarrier = (e, items) => {
    console.log(items.surchargeType, items.surcharge, 'surcharge');
    e.stopPropagation();
    setPronmbsApiData(items);
    // const items = data.row.original;
    setOpen(true);
    setSurchargeType(items.surchargeType ? items.surchargeType : '%');
    setSurchargeValue(items.surcharge ? items.surcharge : '');
    setCarrierID(items.id);
    setCarrierStatus(items.active);
    items.proNumberSuffixPosition &&
      setProNumbersuffixPosition(items.proNumberSuffixPosition);
    items.proNumberSuffix && setProNumberSuffixValue(items.proNumberSuffix);
    items.proNumberStart && setProNumberFrom(items.proNumberStart);
    items.proNumberEnd && setProNumberTo(items.proNumberEnd);
    items.proNumberAlertOn && setProNumberAlert(items.proNumberAlertOn);
    items.proNumberLeft
      ? setProNumberLeft(items.proNumberLeft)
      : setProNumberLeft(0);
    items.proNumberTotals && setProNumberTotals(items.proNumberTotals);
    // setThirdPatyAccNmb(items.thirdPartyAccountNumber);
    setThirdPatyAccDetails({
      ...thirdPatyAccDetails,
      id: items.id ? items.id : 0,
      thirdPartyAccountNumber: items.thirdPartyAccountNumber
        ? items.thirdPartyAccountNumber
        : '',
      companyName: items.tpCompanyName ? items.tpCompanyName : '',
      streetLines: items.tpStreetLines ? items.tpStreetLines : '',
      city: items.tpCity ? items.tpCity : '',
      state: items.tpStateOrProvinceCode ? items.tpStateOrProvinceCode : '',
      postalCode: items.tpPostalCode ? items.tpPostalCode : '',
      countryCode: items.tpCountryCode ? items.tpCountryCode : ''
    });

    items.name && setConfiguredCarrierName(items.name);
    setPaymentTerms(items.rqSettingType);
    setRatesType(items.rqSettingTypeInfo);
    setGuaranteedType(items.rqSettingTypeFilter);
    setSecondPaymentTerms(items.rqSettingType2);
    setSecondRatesType(items.rqSettingTypeInfo2);
    setSecondGuaranteedType(items.rqSettingTypeFilter2);
  };

  const handleUpdateCarrierRequest = () => {
    let obj = {
      id: carrierID,
      // status: carrierStatus,
      // thirdPartyAccountNumber: thirdPatyAccNmb,
      proNumberSuffix: proNumberSuffixValue,
      proNumberSuffixPosition: proNumberSuffixPosition,
      proNumberStart: proNumberFrom ? proNumberFrom : 0,
      proNumberEnd: proNumberTo ? proNumberTo : 0,
      proNumberLeft:
        !proNmbChanged && proNumberLeft !== null
          ? proNumberLeft
          : handleRemainingPro(),
      proNumberAlertOn: proNumberAlert ? proNumberAlert : 0
    };
    UpdateCarrierProSeries(obj)
      .then(res => {
        successToast('Carrier Settings Updated!');
        setOpen(false);
        setOpenConfirmDialog(false);
        handleClose();
        GetCarriers(AgencyID)
          .then(
            res => res.status === 200 && handleSuccesGetCarriers(res, 'LTL')
          )
          .catch(err => console.log(err, 'err'));
      })
      .catch(err => {
        ErrorToast(err?.message);
        handleConfirmDialogClose();
        console.log(err, 'err');
      });
  };

  const mncolumns = [
    {
      accessorKey: 'logo',
      id: 'logo',
      header: 'Logo',
      size: 1,
      Cell: ({ cell, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AvatarGroup spacing='small'>
              <Avatar
                sx={{
                  bgcolor: 'ButtonFace',
                  objectFit: 'contain'
                  // mr: 1
                }}
                imgProps={{
                  style: {
                    height: 45
                  }
                }}
                src={base_logo_url + cell.getValue()}
              />
            </AvatarGroup>
          </Box>
          {row.original.isOwner &&
            ((userPermissionsArr &&
              userPermissionsArr?.filter(permissions => permissions.level4)
                .length) ||
              userPermissionsArr?.filter(permissions => permissions.level2)
                .length ||
              UserIsAdmin) && (
              <div
                className={
                  isMobile
                    ? 'table-row-buttons-users-mobile py-0'
                    : 'table-row-buttons-users py-1'
                }
                style={
                  isMobile
                    ? { marginLeft: '-70px', position: 'absolute' }
                    : { marginLeft: '-5vw', position: 'absolute' }
                }
              >
                <button
                  onClick={event => {
                    setCreateUpdateCarrier(true);
                    history.push('/create-carrier', row.original);
                  }}
                  class={
                    isMobile
                      ? 'bg-grey border-0 p-2 fw-bold'
                      : 'bg-white border-0 p-2 fw-bold'
                  }
                  style={{
                    color: '#3361FF',
                    borderRadius: '4px',
                    margin: '0 0 0 40'
                  }}
                >
                  <Tooltip title='View / Update'>
                    <svg
                      className='mx-0'
                      width='17'
                      height='17'
                      viewBox='0 0 13 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                        fill='#3361FF'
                      />
                    </svg>
                  </Tooltip>
                </button>
              </div>
            )}

          {((value !== '2' &&
            filteredCarrierPermissions &&
            filteredCarrierPermissions.filter(permissions => permissions.level2)
              .length) ||
            (value !== '2' && UserIsAdmin)) && (
            <div
              className={
                isMobile
                  ? 'table-row-buttons-users-mobile py-2'
                  : 'table-row-buttons-users py-2'
              }
              style={
                isMobile
                  ? { marginLeft: '-30px', position: 'absolute' }
                  : { marginLeft: '-2vw', position: 'absolute' }
              }
            >
              <Tooltip arrow title='Settings'>
                <SettingsIcon
                  style={{
                    color: '#3361FF'
                  }}
                  sx={{
                    cursor: 'pointer',
                    marginRight: 2
                  }}
                  onClick={e => handleUpdateCarrier(e, row.original)}
                  // checked={items.active}
                />
              </Tooltip>
            </div>
          )}
        </>
      )
    },
    {
      accessorKey: 'name',
      id: 'name',
      header: 'Carrier Name'
    },
    {
      accessorKey: 'contactEmail',
      id: 'contactEmail',
      header: 'Carrier Email'
    },

    {
      accessorKey: 'cSubType',
      id: 'cSubType',
      header: 'SubType'
    },
    {
      accessorKey: 'thirdPartyAccountNumber',
      id: 'thirdPartyAccountNumber',
      header: '3rd Party Account'
    },
    {
      accessorKey: 'proNumberStart',
      id: 'proNumberStart',
      header: 'ProNumber Start'
    },
    {
      accessorKey: 'proNumberEnd',
      id: 'proNumberEnd',
      header: 'ProNumber End'
    },
    {
      accessorKey: 'proNumberTotals',
      id: 'proNumberTotals',
      header: 'Total ProNumbers'
    },
    {
      accessorKey: 'proNumberLeft',
      id: 'proNumberLeft',
      header: <div style={{ whiteSpace: 'nowrap' }}>Remaining ProNumbers</div>
    }
  ];

  const [value, setValue] = useState('1');
  const [columnsData, setColumnsData] = useState(mncolumns);

  useEffect(() => {
    if (props?.location?.state === 'Non-LTL') {
      setValue('2');
      handleChange('event', '2');
      // setTLCarriers(TLcarriers);
    }
  }, []);

  useEffect(() => {
    // const filteredCol = mncolumns;
    if (value === '1' || value === '3') {
      const filteredCol = mncolumns.filter(col => {
        return (
          col.accessorKey !== 'contactEmail' && col.accessorKey !== 'cSubType'
        );
      });
      setColumnsData(filteredCol);
    }
    if (value === '2') {
      const filteredCol = mncolumns.filter(col => {
        return (
          col.accessorKey !== 'thirdPartyAccountNumber' &&
          col.accessorKey !== 'proNumberStart' &&
          col.accessorKey !== 'proNumberEnd' &&
          col.accessorKey !== 'proNumberLeft' &&
          col.accessorKey !== 'proNumberTotals'
        );
      });
      setColumnsData(filteredCol);
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    const filteredCol = mncolumns;
    setValue(newValue);
    if (newValue === '1') {
      setColumnsData(filteredCol);
      setCarriers(LTLCarriers);
    }
    if (newValue === '2') {
      const filteredCol = columnsData.filter(col => {
        return (
          col.accessorKey !== 'thirdPartyAccountNumber' &&
          col.accessorKey !== 'proNumberStart' &&
          col.accessorKey !== 'proNumberEnd' &&
          col.accessorKey !== 'proNumberLeft' &&
          col.accessorKey !== 'proNumberTotals' &&
          col.accessorKey !== 'cSubType'
        );
      });
      setColumnsData(filteredCol);
      setCarriers(TLcarriers);
    }
    if (newValue === '3') {
      setColumnsData(filteredCol);
      setCarriers(parcelCarriers);
    }
  };
  const handleProNumberFromChange = e => {
    const inputValue = e.target.value;
    const isValidInput = /^[0-9]\d*$/.test(inputValue);
    if (isValidInput) {
      setProNmbChanged(true);
      setProNumbersErrors({
        ...proNumberErrors,
        proNumberFrom: null
      });
      setProNumberFrom(inputValue);
    } else {
      setProNmbChanged(false);
      setProNumberFrom('');
    }
  };

  const handleProNumberToChange = e => {
    const inputValue = e.target.value;
    const isValidInput = /^[0-9]\d*$/.test(inputValue);
    if (isValidInput) {
      setProNmbChanged(true);
      setProNumbersErrors({
        ...proNumberErrors,
        proNumberTo: null
      });
      setProNumberTo(inputValue);
    } else {
      setProNmbChanged(false);

      setProNumberTo('');
    }
  };

  const handleProNumberAlertChange = e => {
    const inputValue = e.target.value;
    const isValidInput = /^[0-9]\d*$/.test(inputValue);
    if (isValidInput) {
      setProNumbersErrors({
        ...proNumberErrors,
        proNumberAlert: null
      });
      setProNumberAlert(inputValue);
    } else {
      setProNumberAlert('');
    }
  };

  const handleCarrierTabChange = (event, newValue) => {
    setCarrierTabValue(newValue);
  };
  const handleSurchargeRequest = () => {
    const surchargeObj = {
      id: carrierID,
      surcharge: surchargeValue ? Number(surchargeValue) : 0,
      surchargeType: surchargeType ? surchargeType : ''
    };

    UpdateCarrierSurcharge(surchargeObj)
      .then(res => {
        res.status === 200 && successToast('Surcharge Value Updated!');
        GetCarriers(AgencyID)
          .then(
            res => res.status === 200 && handleSuccesGetCarriers(res, 'LTL')
          )
          .catch(err => console.log(err, 'err'));
        setOpen(false);
        setTimeout(() => {
          setCarrierTabValue('1');
        }, 2000);
      })
      .catch(err => ErrorToast(err?.message));
    handleClose();
  };
  const handleThirdPartyRequest = () => {
    UpdateCarrierThirdPartyInfo(thirdPatyAccDetails)
      .then(res => {
        GetCarriers(AgencyID)
          .then(
            res => res.status === 200 && handleSuccesGetCarriers(res, 'LTL')
          )
          .catch(err => console.log(err, 'err'));
        res.status === 200 &&
          successToast('3rd party account updated successfully!');
        handleClose();
        setOpen(false);
        setTimeout(() => {
          setCarrierTabValue('1');
        }, 2000);
      })
      .catch(err => {
        ErrorToast(err?.message);
        setOpen(false);
        setTimeout(() => {
          setCarrierTabValue('1');
        }, 2000);
      });
  };

  const handleRemainingPro = () => {
    if (proNumberTo - proNumberFrom > 0) {
      return proNumberTo - proNumberFrom + 1;
    } else return 0;
  };

  const handleRateQuoteSettingsReq = () => {
    const carrierRateSettingsObj = {
      id: carrierID,
      type: secondPaymentTerms === 'Both' ? secondPaymentTerms : paymentTerms,
      typeDetail: secondPaymentTerms === 'Both' ? secondRatesType : ratesType,
      typeDetailFilter:
        secondPaymentTerms === 'Both'
          ? secondRatesType === 'Guaranteed'
            ? secondGuaranteedType
            : ''
          : ratesType === 'Guaranteed'
          ? guaranteedType
          : '',
      type2:
        paymentTerms === 'Both' || secondPaymentTerms === 'Both'
          ? ''
          : secondPaymentTerms,
      typeDetail2:
        paymentTerms === 'Both' || secondPaymentTerms === 'Both'
          ? ''
          : secondRatesType,
      typeDetailFilter2:
        paymentTerms === 'Both' || secondPaymentTerms === 'Both'
          ? ''
          : secondRatesType === 'Guaranteed'
          ? secondGuaranteedType
          : ''
    };
    UpdateCarrierRateQuoteSettings(carrierRateSettingsObj)
      .then(res => {
        res.status === 200 && successToast('Rate Qoute Settings Updated!');

        GetCarriers(AgencyID)
          .then(
            res => res.status === 200 && handleSuccesGetCarriers(res, 'LTL')
          )
          .catch(err => console.log(err, 'err'));
      })

      .catch(err => ErrorToast(err?.message));

    setOpen(false);
    setTimeout(() => {
      setCarrierTabValue('1');
    }, 2000);
  };
  return (
    <Box
      // sx={{ overflowY: 'scroll' }}
      className={carriersLoader ? 'Box_Loader' : isMobile ? '' : 'Box_Parent'}
    >
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Carriers | Shiptechpro'
            : 'Carriers | TMS Shipping'}
        </title>
      </MetaTags>
      <ToastContainer
        style={
          isMobile
            ? { width: '100%', display: 'flex', justifyContent: 'center' }
            : { width: 'auto' }
        }
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid>
        <Dialog
          PaperProps={
            isMobile
              ? { minWidth: '100vw', height: '100vh' }
              : {
                  style: {
                    // minWidth: '30vw',
                    height: '60vh',
                    minWidth: '40vw'
                  }
                }
          }
          className={isMobile ? '' : 'InnerDialog'}
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{`Configure ${configuredCarrierName} Settings`}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TabContext value={carrierTabValue}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        minWidth: '200px'
                      }}
                    >
                      <TabList
                        scrollButtons='auto'
                        variant='scrollable'
                        onChange={handleCarrierTabChange}
                        aria-label='lab API tabs example'
                      >
                        <Tab label='Pro Number Settings' value='1' />
                        <Tab label='Third Party Settings' value='2' />
                        <Tab label='Surcharge Settings' value='3' />
                        <Tab label='Rate Qoute Settings' value='4' />
                      </TabList>
                    </Box>
                    <TabPanel value='1'>
                      {/* <InputLabel sx={{ mb: 0 }}>
                        Pro Number Settings
                      </InputLabel>
                      <hr /> */}

                      <Grid sx={{ mt: 0 }} container spacing={0}>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <InputLabel>Suffix </InputLabel>
                            <Select
                              disabled={
                                ((userPermissionsArr &&
                                  ((userPermissionsArr.filter(
                                    permissions => permissions.level3
                                  ).length &&
                                    !pronmbsApiData) ||
                                    (userPermissionsArr.filter(
                                      permissions => permissions.level4
                                    ).length &&
                                      pronmbsApiData))) ||
                                  UserIsAdmin) &&
                                pronmbsApiData &&
                                pronmbsApiData.proNumberAlertOn >=
                                  pronmbsApiData.proNumberLeft
                                  ? false
                                  : true
                              }
                              label='Suffix '
                              fullWidth
                              size='small'
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={proNumberSuffixPosition}
                              onChange={handleChangeProNmb}
                            >
                              <MenuItem value={'Pre'}>Pre</MenuItem>
                              <MenuItem value={'Post'}>Post</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            disabled={
                              ((userPermissionsArr &&
                                ((userPermissionsArr.filter(
                                  permissions => permissions.level3
                                ).length &&
                                  !pronmbsApiData) ||
                                  (userPermissionsArr.filter(
                                    permissions => permissions.level4
                                  ).length &&
                                    pronmbsApiData))) ||
                                UserIsAdmin) &&
                              pronmbsApiData &&
                              pronmbsApiData.proNumberAlertOn >=
                                pronmbsApiData.proNumberLeft
                                ? false
                                : true
                            }
                            type='text'
                            fullWidth
                            label={`Enter ${proNumberSuffixPosition}`}
                            size='small'
                            value={proNumberSuffixValue}
                            onChange={e =>
                              setProNumberSuffixValue(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid sx={{ mt: 2 }} container spacing={0}>
                        <Grid item xs={6}>
                          <InputLabel sx={{ mb: 1, fontSize: 12 }}>
                            Enter Pro Number
                          </InputLabel>

                          <TextField
                            disabled={
                              ((userPermissionsArr &&
                                ((userPermissionsArr.filter(
                                  permissions => permissions.level3
                                ).length &&
                                  !pronmbsApiData) ||
                                  (userPermissionsArr.filter(
                                    permissions => permissions.level4
                                  ).length &&
                                    pronmbsApiData))) ||
                                UserIsAdmin) &&
                              pronmbsApiData &&
                              pronmbsApiData.proNumberAlertOn >=
                                pronmbsApiData.proNumberLeft
                                ? false
                                : true
                            }
                            required
                            inputProps={{ min: 0 }}
                            error={Boolean(proNumberErrors['proNumberFrom'])}
                            helperText={proNumberErrors['proNumberFrom']}
                            FormHelperTextProps={{
                              style: { margin: ' 5 0 0 0' }
                            }}
                            // type='number'
                            fullWidth
                            label='From'
                            size='small'
                            value={proNumberFrom}
                            onChange={e => handleProNumberFromChange(e)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={
                              ((userPermissionsArr &&
                                ((userPermissionsArr.filter(
                                  permissions => permissions.level3
                                ).length &&
                                  !pronmbsApiData) ||
                                  (userPermissionsArr.filter(
                                    permissions => permissions.level4
                                  ).length &&
                                    pronmbsApiData))) ||
                                UserIsAdmin) &&
                              pronmbsApiData &&
                              pronmbsApiData.proNumberAlertOn >=
                                pronmbsApiData.proNumberLeft
                                ? false
                                : true
                            }
                            inputProps={{ min: proNumberFrom }}
                            required
                            error={Boolean(proNumberErrors['proNumberTo'])}
                            helperText={proNumberErrors['proNumberTo']}
                            FormHelperTextProps={{
                              style: { margin: ' 5 0 0 0' }
                            }}
                            sx={{ mt: 3.2 }}
                            // type='number'
                            fullWidth
                            label='To'
                            size='small'
                            value={proNumberTo}
                            onChange={e => handleProNumberToChange(e)}
                          />
                        </Grid>
                      </Grid>
                      <Grid sx={{ mt: 2 }} container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            disabled={
                              (userPermissionsArr &&
                                (userPermissionsArr.filter(
                                  permissions => permissions.level3
                                ).length ||
                                  userPermissionsArr.filter(
                                    permissions => permissions.level4
                                  ).length)) ||
                              UserIsAdmin
                                ? false
                                : true
                            }
                            required
                            error={Boolean(proNumberErrors['proNumberAlert'])}
                            helperText={proNumberErrors['proNumberAlert']}
                            FormHelperTextProps={{
                              style: { margin: ' 5 0 0 0' }
                            }}
                            inputProps={{ min: 1, max: proNumberTo }}
                            // type='number'
                            fullWidth
                            label='Enter minimum pro-number for Alert'
                            size='small'
                            value={proNumberAlert}
                            onChange={e => handleProNumberAlertChange(e)}
                          />
                        </Grid>
                      </Grid>
                      {proNumberTo && (
                        <Grid sx={{ mt: 2 }} container spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              InputLabelProps={{ style: { color: 'black' } }}
                              disabled
                              InputProps={{
                                disableUnderline: true
                              }}
                              variant='standard'
                              // type='number'
                              fullWidth
                              label='Total Pro-Numbers'
                              size='small'
                              value={
                                !proNmbChanged && proNumberTotals
                                  ? proNumberTotals
                                  : handleRemainingPro()
                              }
                              // onChange={handleTextFieldChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              InputLabelProps={{ style: { color: 'black' } }}
                              disabled
                              InputProps={{
                                disableUnderline: true
                              }}
                              variant='standard'
                              // type='number'
                              fullWidth
                              label='Remaining Pro-Numbers'
                              size='small'
                              value={
                                !proNmbChanged && proNumberLeft !== null
                                  ? proNumberLeft
                                  : handleRemainingPro()
                              }
                              // onChange={handleTextFieldChange}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>
                    <TabPanel value='2'>
                      <TextField
                        disabled={
                          !UserIsAdmin &&
                          !(
                            userPermissionsArr &&
                            userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length
                          )
                            ? true
                            : false
                        }
                        value={thirdPatyAccDetails.thirdPartyAccountNumber}
                        fullWidth
                        sx={{ mt: 1 }}
                        label='Enter Third Party Account'
                        size='small'
                        onChange={e =>
                          setThirdPatyAccDetails({
                            ...thirdPatyAccDetails,
                            thirdPartyAccountNumber: e.target.value
                          })
                        }
                      />
                      <TextField
                        disabled={
                          !UserIsAdmin &&
                          !(
                            userPermissionsArr &&
                            userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length
                          )
                            ? true
                            : false
                        }
                        value={thirdPatyAccDetails.companyName}
                        fullWidth
                        sx={{ mt: 2 }}
                        label='Company Name'
                        size='small'
                        onChange={e =>
                          setThirdPatyAccDetails({
                            ...thirdPatyAccDetails,
                            companyName: e.target.value
                          })
                        }
                        // onChange={e => setThirdPatyAccNmb(e.target.value)}
                      />
                      <TextField
                        disabled={
                          !UserIsAdmin &&
                          !(
                            userPermissionsArr &&
                            userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length
                          )
                            ? true
                            : false
                        }
                        value={thirdPatyAccDetails.streetLines}
                        fullWidth
                        sx={{ mt: 2 }}
                        label='Address'
                        size='small'
                        onChange={e =>
                          setThirdPatyAccDetails({
                            ...thirdPatyAccDetails,
                            streetLines: e.target.value
                          })
                        }
                        // onChange={e => setThirdPatyAccNmb(e.target.value)}
                      />
                      <Grid sx={{ display: 'flex' }}>
                        <TextField
                          disabled={
                            !UserIsAdmin &&
                            !(
                              userPermissionsArr &&
                              userPermissionsArr.filter(
                                permissions => permissions.level4
                              ).length
                            )
                              ? true
                              : false
                          }
                          value={thirdPatyAccDetails.city}
                          fullWidth
                          sx={{ mt: 2 }}
                          label='City'
                          size='small'
                          onChange={e =>
                            setThirdPatyAccDetails({
                              ...thirdPatyAccDetails,
                              city: e.target.value
                            })
                          }
                          // onChange={e => setThirdPatyAccNmb(e.target.value)}
                        />
                        <TextField
                          disabled={
                            !UserIsAdmin &&
                            !(
                              userPermissionsArr &&
                              userPermissionsArr.filter(
                                permissions => permissions.level4
                              ).length
                            )
                              ? true
                              : false
                          }
                          value={thirdPatyAccDetails.state}
                          fullWidth
                          sx={{ mt: 2 }}
                          label='State'
                          size='small'
                          onChange={e =>
                            setThirdPatyAccDetails({
                              ...thirdPatyAccDetails,
                              state: e.target.value
                            })
                          }
                          // onChange={e => setThirdPatyAccNmb(e.target.value)}
                        />
                      </Grid>
                      <Grid sx={{ display: 'flex' }}>
                        <TextField
                          disabled={
                            !UserIsAdmin &&
                            !(
                              userPermissionsArr &&
                              userPermissionsArr.filter(
                                permissions => permissions.level4
                              ).length
                            )
                              ? true
                              : false
                          }
                          value={thirdPatyAccDetails.postalCode}
                          fullWidth
                          sx={{ mt: 2 }}
                          label='Postal Code'
                          size='small'
                          onChange={e =>
                            setThirdPatyAccDetails({
                              ...thirdPatyAccDetails,
                              postalCode: e.target.value
                            })
                          }
                          type='number'
                          // onChange={e => setThirdPatyAccNmb(e.target.value)}
                        />
                        <TextField
                          disabled={
                            !UserIsAdmin &&
                            !(
                              userPermissionsArr &&
                              userPermissionsArr.filter(
                                permissions => permissions.level4
                              ).length
                            )
                              ? true
                              : false
                          }
                          value={thirdPatyAccDetails.countryCode}
                          fullWidth
                          sx={{ mt: 2 }}
                          label='Country'
                          size='small'
                          onChange={e =>
                            setThirdPatyAccDetails({
                              ...thirdPatyAccDetails,
                              countryCode: e.target.value
                            })
                          }
                          // onChange={e => setThirdPatyAccNmb(e.target.value)}
                        />
                      </Grid>
                    </TabPanel>
                    <TabPanel value='3'>
                      <Grid sx={{ mt: 0 }} container spacing={0}>
                        <Grid item xs={9}>
                          <TextField
                            // disabled={
                            //   ((userPermissionsArr &&
                            //     ((userPermissionsArr.filter(
                            //       permissions => permissions.level3
                            //     ).length &&
                            //       !pronmbsApiData) ||
                            //       (userPermissionsArr.filter(
                            //         permissions => permissions.level4
                            //       ).length &&
                            //         pronmbsApiData))) ||
                            //     UserIsAdmin) &&
                            //   pronmbsApiData &&
                            //   pronmbsApiData.proNumberAlertOn >=
                            //     pronmbsApiData.proNumberLeft
                            //     ? false
                            //     : true
                            // }
                            type='number'
                            fullWidth
                            label='Enter Surcharge Value'
                            size='small'
                            value={surchargeValue}
                            onChange={e => setSurchargeValue(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <InputLabel>Type </InputLabel>
                            <Select
                              // disabled={
                              //   ((userPermissionsArr &&
                              //     ((userPermissionsArr.filter(
                              //       permissions => permissions.level3
                              //     ).length &&
                              //       !pronmbsApiData) ||
                              //       (userPermissionsArr.filter(
                              //         permissions => permissions.level4
                              //       ).length &&
                              //         pronmbsApiData))) ||
                              //     UserIsAdmin) &&
                              //   pronmbsApiData &&
                              //   pronmbsApiData.proNumberAlertOn >=
                              //     pronmbsApiData.proNumberLeft
                              //     ? false
                              //     : true
                              // }
                              label='Type '
                              fullWidth
                              size='small'
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              value={surchargeType}
                              onChange={e => setSurchargeType(e.target.value)}
                            >
                              <MenuItem value={'%'}>%</MenuItem>
                              <MenuItem value={'$'}>$</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value='4'>
                      {secondPaymentTerms !== 'Both' && (
                        <Grid sx={{ mt: 1 }} container spacing={1}>
                          <Grid item xs={ratesType === 'Guaranteed' ? 4 : 6}>
                            <FormControl fullWidth>
                              <InputLabel>Select Payment Term </InputLabel>
                              <Select
                                // disabled={
                                //   ((userPermissionsArr &&
                                //     ((userPermissionsArr.filter(
                                //       permissions => permissions.level3
                                //     ).length &&
                                //       !pronmbsApiData) ||
                                //       (userPermissionsArr.filter(
                                //         permissions => permissions.level4
                                //       ).length &&
                                //         pronmbsApiData))) ||
                                //     UserIsAdmin) &&
                                //   pronmbsApiData &&
                                //   pronmbsApiData.proNumberAlertOn >=
                                //     pronmbsApiData.proNumberLeft
                                //     ? false
                                //     : true
                                // }
                                label='Select Payment Term  '
                                fullWidth
                                size='small'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={paymentTerms}
                                onChange={e => {
                                  setPaymentTerms(e.target.value);
                                  e.target.value === 'Inbound'
                                    ? setSecondPaymentTerms('OutBound')
                                    : setSecondPaymentTerms('Inbound');
                                }}
                              >
                                <MenuItem value={'Inbound'}>Inbound</MenuItem>
                                <MenuItem value={'OutBound'}>OutBound</MenuItem>
                                <MenuItem value={'Both'}>Both</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={ratesType === 'Guaranteed' ? 4 : 6}>
                            <FormControl fullWidth>
                              <InputLabel>Select Rates Type </InputLabel>
                              <Select
                                // disabled={
                                //   ((userPermissionsArr &&
                                //     ((userPermissionsArr.filter(
                                //       permissions => permissions.level3
                                //     ).length &&
                                //       !pronmbsApiData) ||
                                //       (userPermissionsArr.filter(
                                //         permissions => permissions.level4
                                //       ).length &&
                                //         pronmbsApiData))) ||
                                //     UserIsAdmin) &&
                                //   pronmbsApiData &&
                                //   pronmbsApiData.proNumberAlertOn >=
                                //     pronmbsApiData.proNumberLeft
                                //     ? false
                                //     : true
                                // }
                                label='Select Rates Type  '
                                fullWidth
                                size='small'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={ratesType}
                                onChange={e => {
                                  setGuaranteedType('All');
                                  setRatesType(e.target.value);
                                }}
                              >
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'Standard'}>Standard</MenuItem>
                                <MenuItem value={'Guaranteed'}>
                                  Guaranteed
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {ratesType === 'Guaranteed' && (
                            <Grid item xs={4}>
                              <FormControl fullWidth>
                                <InputLabel>Select Guaranteed Type </InputLabel>
                                <Select
                                  // disabled={
                                  //   ratesType !== 'Guaranteed' ? true : false
                                  // }
                                  // disabled={
                                  //   ((userPermissionsArr &&
                                  //     ((userPermissionsArr.filter(
                                  //       permissions => permissions.level3
                                  //     ).length &&
                                  //       !pronmbsApiData) ||
                                  //       (userPermissionsArr.filter(
                                  //         permissions => permissions.level4
                                  //       ).length &&
                                  //         pronmbsApiData))) ||
                                  //     UserIsAdmin) &&
                                  //   pronmbsApiData &&
                                  //   pronmbsApiData.proNumberAlertOn >=
                                  //     pronmbsApiData.proNumberLeft
                                  //     ? false
                                  //     : true
                                  // }
                                  label='Select Guaranteed Type  '
                                  fullWidth
                                  size='small'
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  value={guaranteedType}
                                  onChange={e =>
                                    setGuaranteedType(e.target.value)
                                  }
                                >
                                  <MenuItem value={'All'}>All</MenuItem>
                                  <MenuItem value={'Quickest'}>
                                    Quickest Delivery
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {paymentTerms !== 'Both' && (
                        <Grid sx={{ mt: 1 }} container spacing={1}>
                          <Grid
                            item
                            xs={secondRatesType === 'Guaranteed' ? 4 : 6}
                          >
                            <FormControl fullWidth>
                              <InputLabel>Select Payment Term </InputLabel>
                              <Select
                                // disabled={
                                //   ((userPermissionsArr &&
                                //     ((userPermissionsArr.filter(
                                //       permissions => permissions.level3
                                //     ).length &&
                                //       !pronmbsApiData) ||
                                //       (userPermissionsArr.filter(
                                //         permissions => permissions.level4
                                //       ).length &&
                                //         pronmbsApiData))) ||
                                //     UserIsAdmin) &&
                                //   pronmbsApiData &&
                                //   pronmbsApiData.proNumberAlertOn >=
                                //     pronmbsApiData.proNumberLeft
                                //     ? false
                                //     : true
                                // }
                                label='Select Payment Term  '
                                fullWidth
                                size='small'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={secondPaymentTerms}
                                onChange={e => {
                                  setSecondPaymentTerms(e.target.value);
                                  e.target.value === 'Inbound'
                                    ? setPaymentTerms('OutBound')
                                    : setPaymentTerms('Inbound');
                                }}
                              >
                                <MenuItem value={'OutBound'}>OutBound</MenuItem>
                                <MenuItem value={'Inbound'}>Inbound</MenuItem>
                                <MenuItem value={'Both'}>Both</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={secondRatesType === 'Guaranteed' ? 4 : 6}
                          >
                            <FormControl fullWidth>
                              <InputLabel>Select Rates Type </InputLabel>
                              <Select
                                // disabled={
                                //   ((userPermissionsArr &&
                                //     ((userPermissionsArr.filter(
                                //       permissions => permissions.level3
                                //     ).length &&
                                //       !pronmbsApiData) ||
                                //       (userPermissionsArr.filter(
                                //         permissions => permissions.level4
                                //       ).length &&
                                //         pronmbsApiData))) ||
                                //     UserIsAdmin) &&
                                //   pronmbsApiData &&
                                //   pronmbsApiData.proNumberAlertOn >=
                                //     pronmbsApiData.proNumberLeft
                                //     ? false
                                //     : true
                                // }
                                label='Select Rates Type  '
                                fullWidth
                                size='small'
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={secondRatesType}
                                onChange={e => {
                                  setSecondGuaranteedType('All');
                                  setSecondRatesType(e.target.value);
                                }}
                              >
                                <MenuItem value={'All'}>All</MenuItem>
                                <MenuItem value={'Standard'}>Standard</MenuItem>
                                <MenuItem value={'Guaranteed'}>
                                  Guaranteed
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {secondRatesType === 'Guaranteed' && (
                            <Grid item xs={4}>
                              <FormControl fullWidth>
                                <InputLabel>Select Guaranteed Type </InputLabel>
                                <Select
                                  // disabled={
                                  //   ratesType !== 'Guaranteed' ? true : false
                                  // }
                                  // disabled={
                                  //   ((userPermissionsArr &&
                                  //     ((userPermissionsArr.filter(
                                  //       permissions => permissions.level3
                                  //     ).length &&
                                  //       !pronmbsApiData) ||
                                  //       (userPermissionsArr.filter(
                                  //         permissions => permissions.level4
                                  //       ).length &&
                                  //         pronmbsApiData))) ||
                                  //     UserIsAdmin) &&
                                  //   pronmbsApiData &&
                                  //   pronmbsApiData.proNumberAlertOn >=
                                  //     pronmbsApiData.proNumberLeft
                                  //     ? false
                                  //     : true
                                  // }
                                  label='Select Guaranteed Type  '
                                  fullWidth
                                  size='small'
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  value={secondGuaranteedType}
                                  onChange={e =>
                                    setSecondGuaranteedType(e.target.value)
                                  }
                                >
                                  <MenuItem value={'All'}>All</MenuItem>
                                  <MenuItem value={'Quickest'}>
                                    Quickest Delivery
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </TabPanel>
                  </TabContext>

                  {/* <hr /> */}
                  {/* <InputLabel sx={{ mt: 1 }}>
                    Third Party Account Settings
                  </InputLabel>
                  <hr /> */}
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size='small' variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={
                (userPermissionsArr &&
                  userPermissionsArr.filter(permissions => permissions.level4)
                    .length) ||
                UserIsAdmin
                  ? false
                  : true
              }
              size='small'
              variant='contained'
              color='success'
              onClick={
                carrierTabValue === '1'
                  ? handleConfirmationCarrierSettins
                  : carrierTabValue === '2'
                  ? handleThirdPartyRequest
                  : carrierTabValue === '3'
                  ? handleSurchargeRequest
                  : handleRateQuoteSettingsReq
              }
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          PaperProps={{
            style: {
              minWidth: '28vw',
              height: '47vh'
            }
          }}
          className='InnerDialog'
          open={openConfirmDialog}
          onClose={handleConfirmDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Confirmation Dialog</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                Changes are un-revertible until it reached to the minimum Alert
                Level
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <InputLabel sx={{ mb: 0 }}>Pro Number Settings</InputLabel> */}
                  {/* <hr /> */}

                  {/* <Grid sx={{ mt: 1 }} container spacing={0}>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Select
                          InputLabelProps={{ style: { color: 'black' } }}
                          disabled
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant='standard'
                          fullWidth
                          size='small'
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={proNumberSuffixPosition}
                          onChange={handleChangeProNmb}
                        >
                          <MenuItem value={'Pre'}>Pre</MenuItem>
                          <MenuItem value={'Post'}>Post</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        InputLabelProps={{ style: { color: 'black' } }}
                        disabled
                        InputProps={{
                          disableUnderline: true
                        }}
                        sx={{ m: 0.5 }}
                        variant='standard'
                        type='text'
                        fullWidth
                        // label={` ${proNumberSuffixPosition}`}
                        size='small'
                        value={proNumberSuffixValue}
                        onChange={e => setProNumberSuffixValue(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid sx={{ mt: 1 }} container spacing={0}>
                    <Grid item xs={6}>
                      <InputLabel sx={{ mb: 1, fontSize: 14 }}>
                        Pro Number
                      </InputLabel>

                      <TextField
                        InputLabelProps={{
                          style: { color: 'black', fontSize: 18 }
                        }}
                        disabled
                        InputProps={{
                          disableUnderline: true
                        }}
                        variant='standard'
                        required
                        error={Boolean(proNumberErrors['proNumberFrom'])}
                        helperText={proNumberErrors['proNumberFrom']}
                        FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                        // type='number'
                        fullWidth
                        label='From'
                        size='small'
                        value={
                          proNumberSuffixPosition === 'Pre'
                            ? proNumberSuffixValue + proNumberFrom
                            : proNumberFrom + proNumberSuffixValue
                        }
                        onChange={e => {
                          setProNumbersErrors({
                            ...proNumberErrors,
                            proNumberFrom: null
                          });
                          setProNumberFrom(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputLabelProps={{
                          style: { color: 'black', fontSize: 18 }
                        }}
                        disabled
                        InputProps={{
                          disableUnderline: true
                        }}
                        variant='standard'
                        required
                        error={Boolean(proNumberErrors['proNumberTo'])}
                        helperText={proNumberErrors['proNumberTo']}
                        FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                        sx={{ mt: 3.2 }}
                        // type='number'
                        fullWidth
                        label='To'
                        size='small'
                        value={
                          proNumberSuffixPosition === 'Pre'
                            ? proNumberSuffixValue + proNumberTo
                            : proNumberTo + proNumberSuffixValue
                        }
                        onChange={e => {
                          setProNumbersErrors({
                            ...proNumberErrors,
                            proNumberTo: null
                          });
                          setProNumberTo(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ mt: 0 }} container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        InputLabelProps={{
                          style: { color: 'black', fontSize: 18 }
                        }}
                        disabled
                        InputProps={{
                          disableUnderline: true
                        }}
                        variant='standard'
                        required
                        error={Boolean(proNumberErrors['proNumberAlert'])}
                        helperText={proNumberErrors['proNumberAlert']}
                        FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                        inputProps={{ min: 1, max: proNumberTo }}
                        // type='number'
                        fullWidth
                        label='minimum pro-number for Alert'
                        size='small'
                        value={proNumberAlert}
                        onChange={e => {
                          setProNumbersErrors({
                            ...proNumberErrors,
                            proNumberAlert: null
                          });
                          setProNumberAlert(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {proNumberTo && (
                    <Grid sx={{ mt: 0 }} container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          InputLabelProps={{
                            style: { color: 'black', fontSize: 18 }
                          }}
                          disabled
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant='standard'
                          // type='number'
                          fullWidth
                          label='Total Pro-Numbers'
                          size='small'
                          value={
                            !proNmbChanged && proNumberTotals
                              ? proNumberTotals
                              : handleRemainingPro()
                          }
                          // onChange={handleTextFieldChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          InputLabelProps={{
                            style: { color: 'black', fontSize: 18 }
                          }}
                          disabled
                          InputProps={{
                            disableUnderline: true
                          }}
                          variant='standard'
                          // type='number'
                          fullWidth
                          label='Remaining Pro-Numbers'
                          size='small'
                          value={
                            !proNmbChanged && proNumberLeft !== null
                              ? proNumberLeft
                              : handleRemainingPro()
                          }
                          // onChange={handleTextFieldChange}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {/* <hr /> */}
                  {/* <InputLabel sx={{ mt: 1 }}>
                    Third Party Account Settings
                  </InputLabel> */}
                  {/* <hr /> */}

                  {/* <TextField
                    InputLabelProps={{
                      style: { color: 'black', fontSize: 18 }
                    }}
                    disabled
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    value={thirdPatyAccNmb}
                    fullWidth
                    sx={{ mt: 1 }}
                    label='Third Party Account'
                    size='small'
                    onChange={e => setThirdPatyAccNmb(e.target.value)}
                  /> */}
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size='small'
              variant='outlined'
              onClick={handleConfirmDialogClose}
            >
              No
            </Button>
            <Button
              size='small'
              variant='contained'
              color='success'
              onClick={handleUpdateCarrierRequest}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid container sx={{ m: 0 }}>
        {carriersLoader ? (
          <CircularProgress />
        ) : (
          <Grid
            xs={12}
            md={12}
            sm={12}
            lg={12}
            xl={12}
            sx={{ backgroundColor: '#fff', p: 0 }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', p: 0 }}>
                <TabList
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab label='LTL ' value='1' />
                  <Tab label='TL ' value='2' />
                  <Tab label='Parcel ' value='3' />
                </TabList>
              </Box>
              <TabPanel sx={isMobile ? { m: -2 } : {}} value={value}>
                {userPermissionsArr !== null && (
                  <MaterialReactTable
                    // state={{ isLoading: shipmentsLoader }}
                    columns={columnsData}
                    data={carriers}
                    enableRowSelection
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: row.getToggleSelectedHandler()

                      // sx: { cursor: 'pointer' },
                    })}
                    // enableMultiSort

                    muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
                    // enableStickyHeader
                    // enableStickyFooter

                    muiTableContainerProps={{
                      sx: isMobile
                        ? { maxHeight: '66vh', minHeight: '66vh' }
                        : { maxHeight: '56vh', minHeight: '50vh' }
                    }}
                    positionToolbarAlertBanner='bottom'
                    initialState={{
                      density: 'compact'
                    }}
                    renderToolbarInternalActions={({ table }) => (
                      <div>
                        <MRT_ToggleGlobalFilterButton
                          table={table}
                          title='Search'
                        />

                        <MRT_ToggleFiltersButton
                          table={table}
                          title='Filters'
                        />

                        <MRT_ToggleDensePaddingButton
                          table={table}
                          title='Spacing'
                        />
                        <MRT_ShowHideColumnsButton
                          table={table}
                          title='Show/Hide Columns'
                        />
                        <MRT_FullScreenToggleButton
                          table={table}
                          title='Fullscreen'
                        />
                        <Tooltip title='Clear All Filters' arrow>
                          <IconButton
                            onClick={() => {
                              table.resetColumnFilters(true);
                              table.setShowColumnFilters(false);
                            }}
                          >
                            <FilterAltOffIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {
                      if (
                        value === '2' &&
                        ((userPermissionsArr &&
                          userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length) ||
                          UserIsAdmin)
                      ) {
                        return (
                          <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <Button
                              size={isMobile ? 'small' : 'medium'}
                              // disabled={
                              //   userPermissionsArr &&
                              //   userPermissionsArr.filter(
                              //     permissions => permissions.level1
                              //   )
                              //     ? true
                              //     : false
                              // }
                              onClick={() => {
                                setCreateUpdateCarrier(true);
                                history.push('/create-carrier');
                              }}
                              className='m-2'
                              style={
                                isMobile
                                  ? {
                                      fontSize: 10,
                                      color: '#3361FF',
                                      backgroundColor: '#E4F3FD'
                                    }
                                  : {
                                      color: '#3361FF',
                                      backgroundColor: '#E4F3FD'
                                    }
                              }
                              variant='contained'
                            >
                              Add Carrier
                              <svg
                                className='ms-2'
                                width='11'
                                height='10'
                                viewBox='0 0 11 10'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M4.94531 9.72396V5.72396H0.945312V4.39062H4.94531V0.390625H6.27865V4.39062H10.2786V5.72396H6.27865V9.72396H4.94531Z'
                                  fill='#3361FF'
                                />
                              </svg>
                            </Button>
                          </div>
                        );
                      }
                    }}
                    renderRowActions
                  />
                )}
              </TabPanel>
            </TabContext>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Carriers;
