import React, { useEffect, useRef, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import { Grid } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const Reports = () => {
  const [embedTokenState, setEmbedTokenState] = useState('');
  const [groupIdState, setGroupIdState] = useState('');
  const [reportIDState, setReportID] = useState('');
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);

  const GetAuthToken = () => {
    return localStorage.getItem('UserAuthToken');
  };

  const accessTokenRequest = () => {
    const authToken = GetAuthToken();
    if (AgencyID) {
      axios
        .get(`https://a.maxease.net/get-tms-report/?agency_Id=${AgencyID}`)
        .then(res => {
          res.status === 200 && setEmbedTokenState(res.data.data.embedToken);
          setReportID(res.data.data.reportID);
          setGroupIdState(res.data.data.groupID);
        })
        .catch(err => console.log(err, 'poer err -------->>'));
    }
  };

  useEffect(() => {
    accessTokenRequest();
  }, []);
  // Create a ref to store the embedded report component
  const reportRef = useRef(null);

  return embedTokenState && groupIdState && reportIDState ? (
    <PowerBIEmbed
      embedConfig={{
        type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report, and create
        id: reportIDState,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportIDState}&groupId=${groupIdState}`,
        accessToken: embedTokenState,
        tokenType: models.TokenType.Embed, // Use models.TokenType.Embed
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false
            }
          },
          background: models.BackgroundType.Transparent // Use models.BackgroundType.Transparent
        }
      }}
      eventHandlers={
        new Map([
          ['loaded', () => console.log('Report loaded')],
          ['rendered', () => console.log('Report rendered')],
          ['error', event => console.log(event.detail)],
          ['visualClicked', () => console.log('visual clicked')],
          ['pageChanged', event => console.log(event)]
        ])
      }
      cssClassName='reportClass'
      getEmbeddedComponent={embeddedReport => {
        reportRef.current = embeddedReport; // Set the report ref
        console.log('Report embedded:', embeddedReport);
      }}
      style={{ outerHeight: '800px' }}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginY: '20%'
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
};

export default Reports;
